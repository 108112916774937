import React, { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import { Skeleton, Button, Form, Input, Spin } from 'antd';
import { SketchOutlined, UserOutlined, CreditCardOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios';

import VestingStatusTable from './VestingStatusTable';
import { CombinedReducer } from '../../../store';
import User from '../../../interfaces/User';
import VestingContract from '../../../interfaces/VestingContract';
import fetchClient from '../../../utils/fetchClient';
import axiosInstance from '../../../utils/axiosInstance';

import './index.scss';

interface IValues {
    investorId: string;
    amount: number;
}

const VestingStatus = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const user = useSelector<CombinedReducer, User>((state) => state.user);

    const [, forceUpdate] = useState({});
    const [investorAccountList, setInvestorAccountList] = useState<any>([]);
    const [vestingContract, setVestingContract] = useState<VestingContract | null>(null);
    const [isLoading, setLoading] = useState(false)
    const [isProcessing, setIsProcessing] = useState(false);

    const init = async () => {
        try {
            setLoading(true);
            try {
                const vestingInfo = (await axiosInstance.get("/vesting_info/get")).data.data;
                setInvestorAccountList(vestingInfo.investors);
                setVestingContract(vestingInfo.vesting_contract);
            } catch (_) { }
            setLoading(false);
        } catch (err) {
            console.log("err when fetching vestingInfo: ", err);
            setLoading(false);
        }
    }

    const onFinish = async (values: IValues) => {
        await handleVest(values)
    };

    const handleVest = async (params: IValues) => {
        try {
            setIsProcessing(true);

            const {
                amount,
                investorId
            } = params;

            const newInvestorData = await fetchClient.post(
                "/vesting_info/vest",
                {
                    investor_id: investorId,
                    amount: Number(amount)
                }
            );
            investorAccountList.unshift({ user: newInvestorData?.data?.data?.user });
            setInvestorAccountList(investorAccountList);

            dispatch({ type: "UPDATE_USER_BALANCE", payload: (user?.balance - amount) });
            toast.success("Successed");

            setIsProcessing(false);
        } catch (e: any) {
            console.log(e)
            setIsProcessing(false);
            if (e?.response?.data && e?.response?.data?.message) {
                toast.error(e?.response?.data?.message);
            } else if (e?.response?.data && e?.response?.data?.detail) {
                toast.error(e?.response?.data?.detail);
            } else {
                toast.error("Failed to initialize vesting account");
            }
        }
    }

    const handleInitializeVestingContract = async () => {
        try {
            setIsProcessing(true);
            const vestingContract = (await fetchClient.post("/vesting_info/initialize_contract")).data.data;
            setVestingContract(vestingContract);
            toast.success("Successed to initialize vesting contract");
            setIsProcessing(false);
        } catch (e: any) {
            setIsProcessing(false);
            if (e?.response?.data && e?.response?.data?.message) {
                toast.error(e?.response?.data?.message);
            } else {
                toast.error("Failed to initialize vesting contract");
            }
        }
    }

    useEffect(() => {
        forceUpdate({});
        init();
    }, []);

    return (
        <div className='admin-page-card'>
            <Spin spinning={isLoading || isProcessing}>
                {
                    !vestingContract ? (
                        <button
                            className="bindapp-btn main-pool-init-btn"
                            onClick={handleInitializeVestingContract}
                        >
                            Initialize Vesting Contract
                        </button>
                    ) : (
                        <>
                            <div className='title'>Vesting Status</div>
                            <div className='vesting-contract-owner'>
                                Contract Owner: {vestingContract?.vesting_contract_owner?.toString()}
                            </div>
                            <div className='vesting-contract-owner'>
                                Total amount claimed upfront: {vestingContract?.total_amount_of_upfront_claimed}
                            </div>
                            <div className='vesting-contract-owner'>
                                Total amount of tokens withdrawn: {vestingContract?.total_amount_of_tokens_withdrawn}
                            </div>
                            <div className='vesting-contract-owner'>
                                Total amount of tokens remained: {vestingContract?.total_amount_of_tokens_remained}
                            </div>
                            <Col sm={12} md={12} lg={12} className="mt-5 mb-1">
                                <Form
                                    form={form}
                                    name="horizontal_login"
                                    layout="inline"
                                    className="justify-content-center"
                                    onFinish={onFinish}
                                >
                                    <Form.Item
                                        name="investorId"
                                        className="mb-2"
                                        style={{ minWidth: '38%' }}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input uuid of an investor!',
                                            },
                                        ]}
                                    >
                                        <Input prefix={<CreditCardOutlined className="site-form-item-icon" />} placeholder="Investor uuid" />
                                    </Form.Item>
                                    <Form.Item
                                        name="amount"
                                        className="mb-2"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input amount!',
                                            },
                                        ]}
                                    >
                                        <Input
                                            prefix={<SketchOutlined className="site-form-item-icon" />}
                                            type="number"
                                            className="mb-2"
                                            min={0}
                                            placeholder="Amount"
                                        />
                                    </Form.Item>
                                    <Form.Item shouldUpdate>
                                        {() => (
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                                disabled={
                                                    !form.isFieldsTouched(true) ||
                                                    !!form.getFieldsError().filter(({ errors }) => errors.length).length
                                                }
                                            >
                                                Submit
                                            </Button>
                                        )}
                                    </Form.Item>
                                </Form>
                            </Col>

                            <VestingStatusTable
                                investors={investorAccountList}
                                contractOwner={vestingContract?.vesting_contract_owner}
                            />
                        </>
                    )
                }
            </Spin>
        </div >
    );
};

export default VestingStatus;