import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Divider, Skeleton, Spin } from "antd";
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from "react-redux";
import { useWallet } from "@solana/wallet-adapter-react";
import { PublicKey } from "@solana/web3.js";

import MainPoolStatusTable from "./MainPoolStatusTable";
import StakingOnBehalfCard from "./StakingOnBehalfCard";
import FundingModal from "./FundingModal";
import { CombinedReducer } from "../../../store";
import User from "../../../interfaces/User";
import MainPool, { MainPoolStatusEnum } from "../../../interfaces/MainPool";
import fetchClient from "../../../utils/fetchClient";

import "./index.scss";

const MainPoolStatusCard = () => {
    const wallet = useWallet();
    const dispatch = useDispatch();
    const user = useSelector<CombinedReducer, User>((state) => state.user);
    const mainPool = useSelector<CombinedReducer, MainPool>((state) => state.mainPool);

    const [tokenBalance, setTokenBalance] = useState<number>(0);
    const [showFundingModal, setShowFundingModal] = useState<boolean>(false);
    const [rewardAmount, setRewardAmount] = useState<number>(0);
    const [stakedAmount, setStakedAmount] = useState<number>(0);
    const [mainPoolPubkey, setMainPoolPubkey] = useState<string | null>(null);
    const [mainPoolAccount, setMainPoolAccount] = useState<PublicKey>();
    const [isLoading, setLoading] = useState<boolean>(false);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    const initialize = async () => {
        try {
            if (mainPoolPubkey !== null) {
                setLoading(true);
                setMainPoolAccount(new PublicKey(mainPoolPubkey));
                setLoading(false);
            }
        } catch (err) {
            console.log("error in main pool status card", err);
            setLoading(false)
        }
    }

    const loadMainPool = async (mainPoolInfo: any) => {
        const mainPoolPayload: MainPool = {
            mainPoolPubkey: mainPoolInfo?.main_pool_pubkey,
            mainPoolOwner: mainPoolInfo?.main_pool_owner,
            rewardVaultBalance: mainPoolInfo?.reward_vault_balance,
            stakingVaultBalance: mainPoolInfo?.staking_vault_balance,
            totalStakedByBind: mainPoolInfo?.total_staked_by_bind,
            totalStakedByUsers: mainPoolInfo?.total_staked_by_users,
            lockingPeriodOfBehalfStaking: mainPoolInfo?.locking_period_of_behalf_staking,
            userCount: mainPoolInfo?.user_count,
            merchantCount: mainPoolInfo?.merchant_count,
            mainPoolStatus: mainPoolInfo?.main_pool_status
        };

        dispatch({ type: "LOAD_MAIN_POOL", payload: mainPoolPayload });
    }

    const handlePausePool = async (status: MainPoolStatusEnum) => {
        const warningMessage = status == MainPoolStatusEnum.Paused ? "The main pool was already paused" : "The main pool is working now";

        if (status == mainPool.mainPoolStatus) {
            toast.warn(warningMessage);
            return;
        }

        try {
            setIsProcessing(true);
            let updateadMainPool;
            if (status == MainPoolStatusEnum.Paused) {
                updateadMainPool = (await fetchClient.post("/main_pool/pause_pool")).data.data;
            } else {
                updateadMainPool = (await fetchClient.post("/main_pool/unpause_pool")).data.data;
            }
            await loadMainPool(updateadMainPool);

            toast.success(`Successed to ${status == MainPoolStatusEnum.Paused ? 'pause' : 'unpause'} pool`);
            setIsProcessing(false);
        } catch (e: any) {
            console.log("err", e)
            setIsProcessing(false);
            if (e?.response?.data && e?.response?.data?.message) {
                toast.error(e?.response?.data?.message);
            } else if (e?.response?.data && e?.response?.data?.detail) {
                toast.error(e?.response?.data?.detail);
            } else {
                toast.error(`Failed to ${status == MainPoolStatusEnum.Paused ? 'pause' : 'unpause'} pool`);
            }
        }
    }

    const handleFundModal = async (status: boolean) => {
        if (mainPool.mainPoolStatus == MainPoolStatusEnum.Paused) {
            toast.warn("The main pool is paused now");
            return;
        }

        if (tokenBalance == 0) {
            toast.warn("You have no tokens");
            return;
        }

        setShowFundingModal(status)
    }

    const handleFund = async (amount: number) => {
        if (tokenBalance < amount) {
            toast.warn("Insufficient balance");
            return;
        }

        try {
            setIsProcessing(true);
            const updateadMainPool = (await fetchClient.post("/main_pool/fund_tokens", { amount })).data.data;
            await loadMainPool(updateadMainPool);
            setRewardAmount((prev: number) => {
                return prev + amount;
            });
            dispatch({ type: "UPDATE_USER_BALANCE", payload: (user?.balance - amount) });
            dispatch({ type: "UPDATE_REWARD_VAULT_BALANCE", payload: (mainPool.rewardVaultBalance + amount) });

            toast.success("Successed to fund");
            setIsProcessing(false);
        } catch (e: any) {
            setIsProcessing(false);
            if (e?.response?.data && e?.response?.data?.message) {
                toast.error(e?.response?.data?.message);
            } else if (e?.response?.data && e?.response?.data?.detail) {
                toast.error(e?.response?.data?.detail);
            } else {
                toast.error("Failed to fund tokens");
            }
        }
    }

    const handleInitializeMainPool = async () => {
        try {
            setIsProcessing(true);
            const mainPoolInfo = (await fetchClient.post("/main_pool/initialize")).data.data;
            await loadMainPool(mainPoolInfo);
            setIsProcessing(false);
            toast.success("Successed to initialize the main pool");
        } catch (e: any) {
            setIsProcessing(false);
            if (e?.response?.data && e?.response?.data?.message) {
                toast.error(e?.response?.data?.message);
            } else if (e?.response?.data && e?.response?.data?.detail) {
                toast.error(e?.response?.data?.detail);
            } else {
                toast.error("Failed to initialize the main pool");
            }
        }
    }

    const onCompleteStakeOnBehalf = async (amount: number) => {
        setStakedAmount((prev: number) => {
            return prev + amount;
        });
    };

    function delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    useEffect(() => {
        (async () => {
            await initialize();
        })();
    }, [mainPoolPubkey]);

    useEffect(() => {
        setTokenBalance(Number(user?.balance));
    }, [user?.balance]);

    useEffect(() => {
        if (mainPool?.mainPoolPubkey) {
            setMainPoolPubkey(mainPool?.mainPoolPubkey);
        }
    }, [mainPool?.mainPoolPubkey]);

    return (
        <div className="admin-page-card main-pool-info mb-5">
            <Skeleton loading={isLoading} active>
                <div className="w-100">
                    <Spin spinning={isProcessing}>
                        {
                            mainPoolPubkey == null ? (
                                <button
                                    className="bindapp-btn main-pool-init-btn"
                                    onClick={handleInitializeMainPool}
                                >
                                    Initialize Main Pool
                                </button>
                            ) : (
                                <>
                                    <div className="info-data">
                                        <div className="title">MainPool Inforamtion</div>
                                        <Row>
                                            <span><strong>MainPool Pubkey: </strong>{mainPoolPubkey}</span>
                                        </Row>
                                        <Row>
                                            <span><strong>MainPool Owner: </strong>{mainPool?.mainPoolOwner}</span>
                                        </Row>
                                        {/* <Row>
                                            <span><strong>Token amount for reward: </strong>{mainPool?.rewardVaultBalance?.toLocaleString()}</span>
                                        </Row> */}
                                        <Row>
                                            <span><strong>Total staked token amount: </strong>{mainPool?.stakingVaultBalance?.toLocaleString()}</span>
                                        </Row>
                                        <Row>
                                            <span><strong>Total staked by bind: </strong>{mainPool?.totalStakedByBind?.toLocaleString()}</span>
                                        </Row>
                                        <Row>
                                            <span><strong>Total staked by users: </strong>{mainPool?.totalStakedByUsers?.toLocaleString()}</span>
                                        </Row>
                                        <Row>
                                            <span><strong>Pool status: </strong>{mainPool?.mainPoolStatus == MainPoolStatusEnum.Paused ? 'Paused' : 'Unpaused'}</span>
                                        </Row>
                                    </div>

                                    <Row className="mt-4 justify-content-center">
                                        {/* <Col sm={12} md={4} lg={4}>
                                            <button
                                                className="bindapp-btn main-pool-info-btn"
                                                onClick={() => handleFundModal(true)}
                                            >
                                                Fund
                                            </button>
                                        </Col> */}

                                        <Col sm={12} md={4} lg={4}>
                                            <button
                                                className="bindapp-btn main-pool-info-btn"
                                                onClick={() => handlePausePool(MainPoolStatusEnum.Paused)}
                                            >
                                                Pause pool
                                            </button>
                                        </Col>

                                        <Col sm={12} md={4} lg={4}>
                                            <button
                                                className="bindapp-btn main-pool-info-btn"
                                                onClick={() => handlePausePool(MainPoolStatusEnum.Unpaused)}
                                            >
                                                Unpause pool
                                            </button>
                                        </Col>
                                    </Row>

                                    <Divider className="horizontal-divider" />

                                    <StakingOnBehalfCard
                                        tokenBalance={tokenBalance}
                                        contractOwner={mainPool?.mainPoolOwner}
                                        mainPoolAccount={mainPoolAccount}
                                        mainPoolInfo={mainPool}
                                        onCompleteStakeOnBehalf={onCompleteStakeOnBehalf}
                                    />

                                    <MainPoolStatusTable />

                                    <FundingModal
                                        show={showFundingModal}
                                        tokenBalance={tokenBalance}
                                        handleModal={handleFundModal}
                                        handleFund={handleFund}
                                    />
                                </>
                            )
                        }
                    </Spin>
                </div>
            </Skeleton>
        </div>
    );
};

export default MainPoolStatusCard;