import React from 'react';
import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { toast } from 'react-toastify';
import { MainPoolActionType } from '../../../interfaces/MainPool';
import './index.scss';

const StakingModal = (props: any) => {
    const [tokenAmount, setTokenAmount] = useState<number>(0);
    const [lockingPeriod, setLockingPeriod] = useState<number>(2 * 30 * 86400);

    const {
        show,
        actionType,
        tokenBalance,
        selfStakedAmount,
        handleModal,
        handleStake,
        handleUnstake
    } = props;

    const handleClose = () => {
        setTokenAmount(0);
        handleModal(false);
    };

    const handleSubmit = async () => {
        if (!tokenAmount) {
            toast.warn("Please fill amount");
            return;
        }

        if (tokenAmount * 1 <= 0) {
            toast.warn("Please input corrent number");
            return;
        }

        if (actionType == MainPoolActionType.Staking && tokenAmount > tokenBalance) {
            toast.warn("Insufficient amount");
            return;
        }

        if (actionType == MainPoolActionType.Unstaking && tokenAmount > selfStakedAmount) {
            toast.warn("Insufficient amount");
            return;
        }

        handleModal(false);
        if (actionType == MainPoolActionType.Staking) {
            await handleStake(tokenAmount, lockingPeriod);
        } else if (actionType == MainPoolActionType.Unstaking) {
            await handleUnstake(tokenAmount);
        }
    }

    const handleLockingTime = (e: any) => {
        setLockingPeriod(Number(e.target.value));
    }

    return (
        <Modal
            show={show}
            onHide={handleClose}
            centered
            className='bindapp-modal'
        >
            <Modal.Header closeButton closeVariant='white'>
                <Modal.Title>Stake tokens</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    {
                        actionType == MainPoolActionType.Staking && (
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Current token amount</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={tokenBalance?.toLocaleString()}
                                    disabled
                                />
                            </Form.Group>
                        )
                    }
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Amount</Form.Label>
                        <Form.Control
                            onInput={(e: any) => setTokenAmount(Number(e?.target?.value))}
                            type="number"
                            placeholder="Please input amount"
                            autoFocus
                        />
                    </Form.Group>
                    {
                        actionType == MainPoolActionType.Staking && (
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Locking Period</Form.Label>
                                <Form.Select
                                    defaultValue={lockingPeriod}
                                    onChange={handleLockingTime}
                                >
                                    <option value={2 * 30 * 86400}>2 months</option>
                                    <option value={3 * 30 * 86400}>3 months</option>
                                    <option value={4 * 30 * 86400}>4 months</option>
                                </Form.Select>
                            </Form.Group>
                        )
                    }
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={() => handleSubmit()}>
                    Submit
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default StakingModal;