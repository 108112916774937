import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Statistic, Spin } from 'antd';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from "react-redux";

import { useWallet } from "@solana/wallet-adapter-react";
import {
    TOKEN_PROGRAM_ID,
    getAssociatedTokenAddress
} from "@solana/spl-token";
import { Cluster, clusterApiUrl, Connection, LAMPORTS_PER_SOL, PublicKey, Transaction } from "@solana/web3.js";
import { Program, Provider } from "@project-serum/anchor";
import { Wallet } from '@project-serum/anchor/dist/cjs/provider';
import * as anchor from "@project-serum/anchor";
import stakingIdl from "../../idl/staking-idl.json";
import StakingModal from './Modal';
import TokenStakedByAdminTable from './Table';
import User from '../../interfaces/User';
import IMainPool, { MainPoolActionType, MainPoolStatusEnum } from '../../interfaces/MainPool';
import { CombinedReducer } from '../../store';
import fetchClient from '../../utils/fetchClient';
import { 
    REACT_APP_BIND_TOKEN_MINT_ADDRESS, 
    REACT_APP_SOLANA_NETWORK 
} from '../../utils/constants';

import './index.scss';


const bindTokenMint = new PublicKey(REACT_APP_BIND_TOKEN_MINT_ADDRESS!);
const programID = new PublicKey(stakingIdl.metadata.address);
const network = clusterApiUrl(REACT_APP_SOLANA_NETWORK as Cluster);
const connection = new Connection(network, "processed");

const MainPool = () => {
    const { Countdown } = Statistic;
    const wallet = useWallet();
    const dispatch = useDispatch();
    const user = useSelector<CombinedReducer, User>((state) => state.user);
    const mainPool = useSelector<CombinedReducer, IMainPool>((state) => state.mainPool);

    const [showModal, setShowModal] = useState<boolean>(false);
    const [actionType, setActionType] = useState<MainPoolActionType>(MainPoolActionType.Staking);
    const [endtime, setEndtime] = useState<number>(0);
    const [totalStakedAmount, setTotalStakedAmount] = useState<number>(0);
    const [selfStakedAmount, setSelfStakedAmount] = useState<number>(0);
    const [adminStakedAmount, setAdminStakedAmount] = useState<number>(0);
    const [tokenBalance, setTokenBalance] = useState<number>(0);
    const [mainPoolKey, setMainPoolKey] = useState<PublicKey | null>(null);
    const [isCreatedUser, setIsCreatedUser] = useState<boolean>(false);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    async function getProvider() {
        const provider = new Provider(connection, wallet as Wallet, { commitment: 'processed' });
        return provider;
    }

    const initialize = async () => {
        try {
            if (mainPoolKey !== null) {
                setLoading(true);

                const provider = await getProvider();
                const program = new Program(stakingIdl as anchor.Idl, programID, provider);
                const [userAccount] = await anchor.web3.PublicKey.findProgramAddress(
                    [provider.wallet.publicKey.toBuffer(), mainPoolKey.toBuffer()],
                    program.programId
                );

                try {
                    const userInfo = await program.account.user.fetch(userAccount);
                    const totalStakedAmount = userInfo?.balanceStaked.toNumber();
                    const selfStakedAmount = userInfo?.balanceSelfStaked.toNumber();
                    const adminStakedAmount = totalStakedAmount - selfStakedAmount;

                    setIsCreatedUser(true);
                    setTotalStakedAmount(totalStakedAmount / LAMPORTS_PER_SOL);
                    setSelfStakedAmount(selfStakedAmount / LAMPORTS_PER_SOL);
                    setAdminStakedAmount(adminStakedAmount / LAMPORTS_PER_SOL);
                    setEndtime(userInfo?.endTs?.toNumber());
                } catch (_) { }

                setLoading(false);
            }
        } catch (_) {
            setLoading(false);
        }
    }

    const onCompletedWithdraw = async (amount: number) => {
        setTokenBalance(prev => {
            return prev * 1 + amount * 1
        })

        setTotalStakedAmount(prev => {
            return prev * 1 - amount * 1
        })

        setAdminStakedAmount(prev => {
            return prev * 1 - amount * 1
        })
    }

    const showStakingModal = (status: boolean) => {
        if (mainPool.mainPoolStatus == MainPoolStatusEnum.Paused) {
            toast.warn("You can't stake tokens because the main pool is paused");
            return;
        }

        if (!wallet.publicKey) {
            toast.warn("Please connect your wallet");
            return;
        }

        if (tokenBalance == 0) {
            toast.warn("You have no tokens");
            return;
        }

        setShowModal(status);
    }

    const handleStake = async (amount: number, lockingPeriod: number) => {
        try {
            setIsProcessing(true);

            const provider = await getProvider();
            const program = new Program(stakingIdl as anchor.Idl, programID, provider);

            const poolObject = await program.account.pool.fetch(mainPoolKey!);
            const userWalletAta = await getAssociatedTokenAddress(bindTokenMint, provider.wallet.publicKey);
            const [userAccount, userAccountNonce] = await anchor.web3.PublicKey.findProgramAddress(
                [provider.wallet.publicKey.toBuffer(), mainPoolKey!.toBuffer()],
                program.programId
            );
            const [poolSigner] = await anchor.web3.PublicKey.findProgramAddress(
                [mainPoolKey!.toBuffer()],
                program.programId
            );

            const transaction = new Transaction();
            if (!(await provider.connection.getAccountInfo(userAccount))) {
                transaction.add(
                    program.instruction.createUser(
                        userAccountNonce,
                        {
                            accounts: {
                                pool: mainPoolKey!,
                                user: userAccount,
                                owner: provider.wallet.publicKey,
                                payer: provider.wallet.publicKey,
                                systemProgram: anchor.web3.SystemProgram.programId,
                            },
                        }
                    )
                );
            }

            transaction.add(
                program.instruction.stake(
                    new anchor.BN(amount * LAMPORTS_PER_SOL),
                    new anchor.BN(lockingPeriod),
                    {
                        accounts: {
                            pool: mainPoolKey!,
                            stakingVault: poolObject.stakingVault,
                            user: userAccount,
                            owner: provider.wallet.publicKey,
                            stakeFromAccount: userWalletAta,
                            poolSigner,
                            clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
                            tokenProgram: TOKEN_PROGRAM_ID,
                        },
                    }
                )
            );

            const tx = await wallet.sendTransaction(transaction, connection);
            console.log("staking in main pool tx", tx)

            try {
                await saveActions(amount, lockingPeriod);
            } catch (e: any) {
                if (e?.response?.data && e?.response?.data?.message) {
                    toast.error(e?.response?.data?.message);
                } else {
                    toast.error("Internal server error");
                }

                return;
            }

            dispatch({ type: "UPDATE_USER_BALANCE", payload: (user?.balance - amount) });
            await delay(1500);
            const userInfo = await program.account.user.fetch(userAccount);
            const totalStakedAmount = userInfo?.balanceStaked.toNumber();
            const selfStakedAmount = userInfo?.balanceSelfStaked.toNumber();
            const adminStakedAmount = totalStakedAmount - selfStakedAmount;

            setIsCreatedUser(true);
            setTotalStakedAmount(totalStakedAmount / LAMPORTS_PER_SOL);
            setSelfStakedAmount(selfStakedAmount / LAMPORTS_PER_SOL);
            setAdminStakedAmount(adminStakedAmount / LAMPORTS_PER_SOL);
            setEndtime(userInfo?.endTs?.toNumber());

            setIsProcessing(false);
            toast.success("Successed to stake tokens in the main pool");
        } catch (err) {
            console.log("staking in main pool err", err);
            toast.error("Failed to stake tokens in the main pool");
            setIsProcessing(false);
        }
    }

    const handleUnstake = async (amount: number) => {
        if (mainPool.mainPoolStatus == MainPoolStatusEnum.Paused) {
            toast.warn("You can't unstake tokens because the main pool is paused");
            return;
        }

        if (!isCreatedUser) {
            toast.warn("You didn't stake tokens yet");
            return;
        }

        if (selfStakedAmount <= 0) {
            toast.warn("You have no tokens staked by yourself");
            return;
        }

        let slot = await connection.getSlot();
        let currentTime = await connection.getBlockTime(slot);

        if (endtime > currentTime!) {
            toast.warn("You can't unstake tokens during locking period");
            return;
        }

        try {
            setIsProcessing(true)
            const provider = await getProvider();
            const program = new Program(stakingIdl as anchor.Idl, programID, provider);

            let poolObject = await program.account.pool.fetch(mainPoolKey!);

            const [
                poolSigner,
                nonce,
            ] = await anchor.web3.PublicKey.findProgramAddress(
                [mainPoolKey!.toBuffer()],
                program.programId
            );

            const userWalletAta = await getAssociatedTokenAddress(bindTokenMint, provider.wallet.publicKey);
            const [
                userPubkey, userNonce,
            ] = await anchor.web3.PublicKey.findProgramAddress(
                [provider.wallet.publicKey.toBuffer(), mainPoolKey!.toBuffer()],
                program.programId
            );

            const tx = await program.rpc.unstake(
                new anchor.BN((totalStakedAmount - adminStakedAmount) * LAMPORTS_PER_SOL),
                {
                    accounts: {
                        pool: mainPoolKey!,
                        stakingVault: poolObject.stakingVault,
                        user: userPubkey,
                        owner: provider.wallet.publicKey,
                        stakeFromAccount: userWalletAta,
                        poolSigner,
                        clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
                        tokenProgram: TOKEN_PROGRAM_ID,
                    },
                }
            );
            console.log("unstake token in main pool => tx", tx)
            await saveActions(amount);

            setTotalStakedAmount((prev: number) => {
                return prev + amount
            });
            setSelfStakedAmount((prev: number) => {
                return prev + amount
            });
            toast.success("Successed to unstake tokens");
            setEndtime(0);
            setIsProcessing(false);
        } catch (err) {
            console.log("unstake token in main pool => err", err)
            toast.error("Failed to unstake tokens");
            setIsProcessing(false);
        }
    }

    const handleclaim = async () => {
        if (mainPool.mainPoolStatus == MainPoolStatusEnum.Paused) {
            toast.warn("You can't claim rewards because the main pool is paused");
            return;
        }

        if (!isCreatedUser) {
            toast.warn("You didn't stake tokens yet");
            return;
        }

        try {
            setIsProcessing(true)
            setActionType(MainPoolActionType.Claiming);

            const provider = await getProvider();
            const program = new Program(stakingIdl as anchor.Idl, programID, provider);

            const poolObject = await program.account.pool.fetch(mainPoolKey!);

            const [
                poolSigner,
                nonce,
            ] = await anchor.web3.PublicKey.findProgramAddress(
                [mainPoolKey!.toBuffer()],
                program.programId
            );

            const userWalletAta = await getAssociatedTokenAddress(bindTokenMint, provider.wallet.publicKey);
            const [
                userPubkey, userNonce,
            ] = await anchor.web3.PublicKey.findProgramAddress(
                [provider.wallet.publicKey.toBuffer(), mainPoolKey!.toBuffer()],
                program.programId
            );

            const tx = await program.rpc.claim(
                {
                    accounts: {
                        pool: mainPoolKey!,
                        stakingVault: poolObject.stakingVault,
                        rewardVault: poolObject.rewardVault,
                        user: userPubkey,
                        owner: provider.wallet.publicKey,
                        rewardAccount: userWalletAta,
                        poolSigner,
                        clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
                        tokenProgram: TOKEN_PROGRAM_ID,
                    },
                }
            );
            console.log("claim tokens => tx", tx)

            const currentTokenBalance = (await provider.connection.getTokenAccountBalance(userWalletAta))?.value?.uiAmount || 0;
            await saveActions(Number(currentTokenBalance) - Number(user.balance));
            dispatch({ type: "UPDATE_USER_BALANCE", payload: currentTokenBalance });

            toast.success("Successed to claim tokens");
            setIsProcessing(false)
        } catch (err) {
            console.log("claim tokens => err", err)
            toast.error("Failed to claim tokens");
            setIsProcessing(false);
        }
    }

    const saveActions = async (amount: number, lockingPeriod?: number) => {
        let data: any = {
            action_type: actionType,
            amount: amount,
            user_id: user?.id,
            successed_status: true
        };

        if (lockingPeriod) {
            data = { ...data, locking_period: lockingPeriod }
        };

        await fetchClient.post("/main_pool_actions/create",data);
    }

    function delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    useEffect(() => {
        (async () => {
            await initialize();
        })()
    }, [wallet, mainPoolKey]);

    useEffect(() => {
        setTokenBalance(Number(user?.balance));
    }, [user?.balance]);

    useEffect(() => {
        if (mainPool?.mainPoolPubkey) {
            setMainPoolKey(new PublicKey(mainPool?.mainPoolPubkey));
        }
    }, [mainPool?.mainPoolPubkey]);

    return (
        <div className='page mainpool-page'>
            <div className='mainpool-page-card'>
                <Spin spinning={isProcessing}>
                    <div className='title'>Main Pool Staking Card</div>
                    <Row>
                        <Col sm='12' md='4' lg='4' className='mb-2'>
                            <div className='mainpool-token-amount-title'>Total Staked Amount</div>
                            <div className='mainpool-token-amount'>{totalStakedAmount} BIND</div>
                        </Col>
                        <Col sm='12' md='4' lg='4' className='mb-2'>
                            <div className='mainpool-token-amount-title'>Self Staked Amount</div>
                            <div className='mainpool-token-amount'>{selfStakedAmount} BIND</div>
                        </Col>
                        <Col sm='12' md='4' lg='4' className='mb-2'>
                            <div className='mainpool-token-amount-title'>Admin Staked Amount</div>
                            <div className='mainpool-token-amount'>{adminStakedAmount} BIND</div>
                        </Col>
                    </Row>
                    <Countdown
                        value={endtime * 1000}
                        format="MM:DD:HH:mm:ss"
                    />
                    <div className='mainpool-btn-group'>
                        <button
                            className='bindapp-btn mainpool-btn'
                            onClick={() => {
                                setActionType(MainPoolActionType.Staking);
                                showStakingModal(true);
                            }}
                        >
                            Stake
                        </button>
                        <button
                            className='bindapp-btn mainpool-btn'
                            onClick={() => {
                                setActionType(MainPoolActionType.Unstaking);
                                showStakingModal(true);
                            }}
                        >
                            Unstake
                        </button>
                        <button
                            className='bindapp-btn mainpool-btn'
                            onClick={handleclaim}
                        >
                            Claim
                        </button>
                    </div>
                </Spin>
            </div>

            <div className='mainpool-page-card mb-5'>
                <div className='title'>Tokens Staked by Admin</div>
                <TokenStakedByAdminTable
                    mainPool={mainPool}
                    onCompletedWithdraw={onCompletedWithdraw}
                />
            </div>

            <StakingModal
                show={showModal}
                actionType={actionType}
                tokenBalance={tokenBalance}
                selfStakedAmount={selfStakedAmount}
                handleModal={showStakingModal}
                handleStake={handleStake}
                handleUnstake={handleUnstake}
            />
        </div>
    );
};

export default MainPool;