import { useEffect } from 'react';
import { useState } from "react";
import { Modal, Row } from "react-bootstrap";
import { Table, Skeleton, Input, Button, Space, Divider, Tooltip, Statistic } from 'antd';
import Highlighter from 'react-highlight-words';
import {
    SearchOutlined,
} from '@ant-design/icons';

import axiosInstance from '../../../../../utils/axiosInstance';
import convertDate from '../../../../../utils/convertDate';
import { MerchantPoolStatusEnum } from '../../MerchantPoolsTable';

import "./index.scss"

interface IProps {
    show: boolean,
    currentMerchantId: string,
    handleModal: any
}

let searchInput: any;
const { Countdown } = Statistic;

const MerchantDetailsModal = (props: IProps) => {
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('')
    const [isLoading, setLoading] = useState(false)
    const [isProcessing, setIsProcessing] = useState(false)
    const [tableData, setTableData] = useState<any>()
    const [largestPoolId, setLargestPoolId] = useState<string | null>(null);
    const [largestPoolSize, setLargestPoolSize] = useState<number | null>(null);
    const [totalStakedAmount, setTotalStakedAmount] = useState<number>(0);

    const {
        show,
        currentMerchantId,
        handleModal,
    } = props;

    const handleClose = () => handleModal(false, "");

    const getColumnSearchProps = (dataIndex: any) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value: any, record: any) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: (visible: any) => {
            if (visible) {
                setTimeout(() => searchInput.select(), 100);
            }
        },
        render: (text: any) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
        confirm();
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
    };

    const handleReset = (clearFilters: any) => {
        clearFilters();
        setSearchText('')
    };

    const columns = [
        {
            title: 'MerchantPoolId',
            dataIndex: 'merchantPoolId',
            key: 'merchantPoolId',
            responsive: ["sm"],
            ...getColumnSearchProps('merchantPoolId'),
            render: (merchantPoolId: string) => (
                <Tooltip placement="topLeft" title={merchantPoolId}>
                    {String(merchantPoolId).substring(0, 10) + "..."}
                </Tooltip>
            ),
        },
        {
            title: 'UserCount',
            dataIndex: 'userCount',
            key: 'userCount',
            responsive: ["sm"],
            ...getColumnSearchProps('userCount'),
        },
        {
            title: 'TokenAmountToNeedToStake',
            dataIndex: 'tokenAmountNeedToStake',
            key: 'tokenAmountNeedToStake',
            responsive: ["sm"],
            ...getColumnSearchProps('tokenAmountNeedToStake'),
        },
        {
            title: 'StakedTokenAmount',
            dataIndex: 'stakedTokenAmount',
            key: 'stakedTokenAmount',
            responsive: ["sm"],
            ...getColumnSearchProps('stakedTokenAmount'),
        },
        {
            title: 'LockingPeriod',
            dataIndex: 'lockingPeriod',
            key: 'lockingPeriod',
            responsive: ["sm"],
            ...getColumnSearchProps('lockingPeriod')
        },
        {
            title: 'ReleaseDate',
            dataIndex: 'releaseDate',
            key: 'releaseDate',
            responsive: ["sm"],
            ...getColumnSearchProps('releaseDate'),
        },
        {
            title: 'TimeRemaining',
            dataIndex: 'timeRemaining',
            key: 'timeRemaining',
            responsive: ["sm"],
            render: (timeRemaining: any, record: any) => (
                <Countdown value={timeRemaining} format="MM:DD:HH:mm:ss" />
            ),

        },
        {
            title: 'MerchantPoolStatus',
            dataIndex: 'merchantPoolStatus',
            key: 'merchantPoolStatus',
            responsive: ["sm"],
            ...getColumnSearchProps('merchantPoolStatus'),
        },
    ];

    const initialize = async (isInit: boolean) => {
        try {
            if (isInit)
                setLoading(true);

            const config = {
                params: {
                    merchant_id: currentMerchantId,
                },
            };
            let largestPoolSize = 0;
            let largestPoolId = "";
            let totalStakedAmount = 0;
            const merchantPools = (await axiosInstance.get("/merchant_pool/get_merchant_pools", config)).data.data;
            const tableData = await Promise.all(
                merchantPools.map(async (item: any, index: any) => {
                    if (item.merchant_pool_status == MerchantPoolStatusEnum.Active && item.merchant_pool_user_count > 0) {
                        largestPoolSize = largestPoolSize > item.merchant_pool_user_count * item.staking_amount
                            ? largestPoolSize
                            : item.merchant_pool_user_count * item.staking_amount;

                        largestPoolId = largestPoolSize > item.merchant_pool_user_count * item.staking_amount
                            ? largestPoolId
                            : item.merchant_pool_id;

                        totalStakedAmount += item.merchant_pool_user_count * item.staking_amount;
                    }

                    const tableRowData = {
                        key: `${index}`,
                        merchantId: item.merchant.merchant_id,
                        merchantPoolId: item.merchant_pool_id,
                        userCount: item.merchant_pool_user_count,
                        stakedTokenAmount: item.merchant_pool_status == MerchantPoolStatusEnum.Active ? item.merchant_pool_user_count * item.staking_amount : 0,
                        tokenAmountNeedToStake: item.staking_amount,
                        lockingPeriod: await convertDate(item.merchant_pool_locking_time),
                        releaseDate: `${new Date(new Date(item.created_at).getTime() + item.merchant_pool_locking_time * 1000).toLocaleString()}`,
                        timeRemaining: new Date(item.created_at).getTime() + item.merchant_pool_locking_time * 1000,
                        merchantPoolStatus: MerchantPoolStatusEnum[item.merchant_pool_status]
                    }

                    return tableRowData;
                })
            );

            setTableData(tableData);
            setTotalStakedAmount(totalStakedAmount);
            if (largestPoolId != "") {
                setLargestPoolId(largestPoolId);
                setLargestPoolSize(largestPoolSize);
            }

            setLoading(false);
        } catch (err) {
            console.log("err", err);
            setLoading(false);
        }
    }

    useEffect(() => {
        (async () => {
            await initialize(true)
        })()
    }, [currentMerchantId])

    return (
        <Modal show={show} fullscreen={true} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Merchant Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Skeleton loading={isLoading} active>
                    {
                        <>
                            <Row>
                                <span>
                                    <strong>MerchantId: </strong>
                                    {currentMerchantId}
                                </span>
                            </Row>
                            <Row>
                                <span>
                                    <strong>Total of awards paid: </strong>
                                    {`N/A`}
                                </span>
                            </Row>
                            <Row>
                                <span>
                                    <strong>Total Staked Amount: </strong>
                                    {totalStakedAmount}
                                </span>
                            </Row>
                            <Row>
                                <span>
                                    <strong>Largest Pool Id: </strong>
                                    {largestPoolId != null ? largestPoolId : "-"}
                                </span>
                            </Row>
                            <Row>
                                <span>
                                    <strong>Largest Pool Size: </strong>
                                    {largestPoolSize != null ? largestPoolSize : "-"}
                                </span>
                            </Row>
                        </>
                    }
                </Skeleton>

                <Divider />
                <Row>
                    {
                        <Skeleton loading={isLoading} active>
                            <Table
                                columns={columns as any}
                                dataSource={tableData}
                                scroll={{ x: 1000 }}
                            />
                        </Skeleton>
                    }
                </Row>
            </Modal.Body>
        </Modal>
    );
}

export default MerchantDetailsModal;