import { useState, useEffect } from 'react'
import { toast } from 'react-toastify';
import { Table, Input, Button, Space, Skeleton, Tooltip, Statistic } from 'antd';
import Highlighter from 'react-highlight-words';
import {
    SearchOutlined,
    DeliveredProcedureOutlined
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import axios from 'axios';

import { useWallet } from "@solana/wallet-adapter-react";
import { TOKEN_PROGRAM_ID, ASSOCIATED_TOKEN_PROGRAM_ID, createAssociatedTokenAccountInstruction, getAssociatedTokenAddress } from "@solana/spl-token";
import { Cluster, clusterApiUrl, Connection, LAMPORTS_PER_SOL, PublicKey, Transaction } from "@solana/web3.js";
import { Program, Provider } from "@project-serum/anchor";
import { Wallet } from '@project-serum/anchor/dist/cjs/provider';
import * as anchor from "@project-serum/anchor";
import stakingIdl from "../../../idl/staking-idl.json";
import { CombinedReducer } from '../../../store';
import User from '../../../interfaces/User';
import { MainPoolStatusEnum } from '../../../interfaces/MainPool';
import fetchClient from '../../../utils/fetchClient';
import {
    REACT_APP_SOLANA_NETWORK,
    REACT_APP_MAIN_POOL_PUBKEY,
    REACT_APP_BIND_TOKEN_MINT_ADDRESS
} from '../../../utils/constants';
import axiosInstance from '../../../utils/axiosInstance';

import "./index.scss"

const programID = new PublicKey(stakingIdl.metadata.address);
const network = clusterApiUrl(REACT_APP_SOLANA_NETWORK as Cluster);
const connection = new Connection(network, "processed");
const mainPoolKey = new PublicKey(REACT_APP_MAIN_POOL_PUBKEY!);
const bindTokenMint = new PublicKey(REACT_APP_BIND_TOKEN_MINT_ADDRESS!);

let searchInput: any;

enum WithdrawnStatusEnum {
    Withdrawn,
    NonWithdrawn
}
const { Countdown } = Statistic;

const TokenStakedByAdminTable = (props: any) => {
    const user = useSelector<CombinedReducer, User>((state) => state.user);

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('')
    const [isLoading, setLoading] = useState(false)
    const [isProcessing, setIsProcessing] = useState(false)
    const [tableData, setTableData] = useState<any>()

    const {
        mainPool,
        onCompletedWithdraw
    } = props
    const wallet = useWallet();

    async function getProvider() {
        const provider = new Provider(connection, wallet as Wallet, { commitment: 'processed' });
        return provider;
    }

    const getColumnSearchProps = (dataIndex: any) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value: any, record: any) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: (visible: any) => {
            if (visible) {
                setTimeout(() => searchInput.select(), 100);
            }
        },
        render: (text: any) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
        confirm();
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
    };

    const handleReset = (clearFilters: any) => {
        clearFilters();
        setSearchText('')
    };

    const columns = [
        {
            title: 'StakedAmount',
            dataIndex: 'stakedAmount',
            key: 'stakedAmount',
            responsive: ["sm"],
            ...getColumnSearchProps('stakedAmount'),
        },
        {
            title: 'EndTime',
            dataIndex: 'endDateTime',
            key: 'endDateTime',
            responsive: ["sm"],
            ...getColumnSearchProps('endDateTime'),
        },
        {
            title: 'CountDown',
            dataIndex: 'endTime',
            key: 'endTime',
            responsive: ["sm"],
            render: (endTime: any, record: any) => (
                <Countdown value={endTime * 1000} format="MM:DD:HH:mm:ss" />
            ),

        },
        {
            title: 'WithdrawnStatus',
            dataIndex: 'withdrawnStatus',
            key: 'withdrawnStatus',
            responsive: ["sm"],
            ...getColumnSearchProps('withdrawnStatus')
        },
        {
            title: 'Action',
            responsive: ["sm"],
            align: "center",
            render: (text: any, record: any) => (
                record.withdrawnStatus == WithdrawnStatusEnum.Withdrawn ? (
                    <DeliveredProcedureOutlined />
                ) : (
                    <Tooltip placement="topLeft" title="Withdraw">
                        <a
                            className='table-action justify-content-center active'
                            onClick={() => handleWithdraw(record.key, record.endTime, record.stakedAmount)}
                        >
                            <DeliveredProcedureOutlined />
                        </a>
                    </Tooltip>
                )
            )
        },
    ];

    const initialize = async (isInit: boolean) => {
        try {
            if (isInit)
                setLoading(true)
            if (!user)
                return;

            const config = {
                params: {
                    user_id: user.id,
                },
            };
            const tokensStakedByAdminInfo = (await axiosInstance.get("/staking_on_behalf/get_info_by_user", config)).data.data;

            const tableData = await Promise.all(
                tokensStakedByAdminInfo.map(async (item: any, index: any) => {
                    const tableRowData = {
                        key: `${index}`,
                        stakedAmount: item.amount,
                        endTime: item.end_time,
                        endDateTime: `${new Date(item.end_time * 1000).toLocaleString()}`,
                        withdrawnStatus: WithdrawnStatusEnum[item.staked_status],
                    }

                    return tableRowData
                })
            )

            setTableData(tableData)

            setLoading(false)
        } catch (err) {
            console.log("err", err)
            setLoading(false)
        }
    }

    const handleWithdraw = async (listIndex: any, endTime: any, stakedAmount: any) => {
        try {
            if (mainPool?.mainPoolStatus == MainPoolStatusEnum.Paused) {
                toast.warn("You can't withdraw tokens because the main pool is paused");
                return;
            }

            if (Number(endTime) * 1000 > Date.now()) {
                toast.warn("You can't withdraw tokens during locking period");
                return;
            }

            setIsProcessing(true);

            const provider = await getProvider();
            const program = new Program(stakingIdl as anchor.Idl, programID, provider);

            let poolObject = await program.account.pool.fetch(mainPoolKey);

            const [
                poolSigner, poolNonce,
            ] = await anchor.web3.PublicKey.findProgramAddress(
                [mainPoolKey.toBuffer()],
                program.programId
            );

            const userWalletAta = await getAssociatedTokenAddress(bindTokenMint, provider.wallet.publicKey);
            const [
                userPubkey, userNonce,
            ] = await anchor.web3.PublicKey.findProgramAddress(
                [provider.wallet.publicKey.toBuffer(), mainPoolKey.toBuffer()],
                program.programId
            );

            const tx = await program.rpc.withdraw(
                listIndex,
                {
                    accounts: {
                        // Stake instance.
                        pool: mainPoolKey,
                        stakingVault: poolObject.stakingVault,
                        // User.
                        user: userPubkey,
                        owner: provider.wallet.publicKey,
                        stakeFromAccount: userWalletAta,
                        // Program signers.
                        poolSigner,
                        // Misc.
                        clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
                        tokenProgram: TOKEN_PROGRAM_ID,
                    },
                });

            await initialize(false)
            await onCompletedWithdraw(stakedAmount)

            console.log("withdraw tx: ", tx)
            toast.success("Successed to unstake tokens");

            setIsProcessing(false)
        } catch (err) {
            console.log("withdraw err: ", err)
            toast.error("Failed to withdraw tokens");
            setIsProcessing(false)
        }

    }

    useEffect(() => {
        (async () => {
            await initialize(true)
        })()
    }, [user])

    return (
        <Skeleton loading={isLoading} active>
            <Table
                className='staked-by-admin'
                columns={columns! as any}
                dataSource={tableData}
                scroll={{ x: 1000 }}
                loading={isProcessing}
            />
        </Skeleton>
    );
}

export default TokenStakedByAdminTable;