import { useState, useEffect } from 'react'
import { Table, Input, Button, Space, Skeleton, Tooltip } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { MainPoolActionType } from '../../../../interfaces/MainPool';
import axiosInstance from '../../../../utils/axiosInstance';

let searchInput: any;
let tableData: any = [];

const MainPoolStatusTable = (props: any) => {
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('')
    const [isLoading, setLoading] = useState(false)

    const getColumnSearchProps = (dataIndex: any) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value: any, record: any) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: (visible: any) => {
            if (visible) {
                setTimeout(() => searchInput.select(), 100);
            }
        },
        render: (text: any) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
        confirm();
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
    };

    const handleReset = (clearFilters: any) => {
        clearFilters();
        setSearchText('')
    };

    const columns = [
        {
            title: 'UserId',
            dataIndex: 'userId',
            key: 'userId',
            responsive: ["sm"],
            fixed: "left",
            ...getColumnSearchProps('userId'),
            render: (userId: any) => (
                <Tooltip placement="topLeft" title={userId}>
                    {String(userId).substring(0, 20) + "..."}
                </Tooltip>
            ),
        },
        {
            title: 'TokenAmount',
            dataIndex: 'tokenAmount',
            key: 'tokenAmount',
            responsive: ["sm"],
            ...getColumnSearchProps('tokenAmount')
        },
        {
            title: 'LockingPeriod(month)',
            dataIndex: 'lockingPeriod',
            key: 'lockingPeriod',
            responsive: ["sm"],
            ...getColumnSearchProps('lockingPeriod'),
            render: (lockingPeriod: any) => (
                lockingPeriod ? lockingPeriod : "-"
            ),
        },
        {
            title: 'ReleaseDate',
            dataIndex: 'releaseDate',
            key: 'releaseDate',
            responsive: ["sm"],
            ...getColumnSearchProps('releaseDate'),
        },
        {
            title: 'ActionType',
            dataIndex: 'actionType',
            key: 'actionType',
            responsive: ["sm"],
            ...getColumnSearchProps('actionType'),
        },
        {
            title: 'ActionTime',
            dataIndex: 'actionTime',
            key: 'actionTime',
            responsive: ["sm"],
            ...getColumnSearchProps('actionTime'),
        },
    ];

    const initialize = async () => {
        try {
            setLoading(true)

            const mainPoolActionsInfo = (await axiosInstance.get("/main_pool_actions/get_all_actions")).data.data;

            tableData = await Promise.all(
                mainPoolActionsInfo.map(async (item: any, index: any) => {
                    const tableRowData = {
                        key: `${index + 1}`,
                        userId: item?.user?.user_id,
                        userPubkey: item?.user?.wallet_pubkey,
                        tokenAmount: item.amount,
                        lockingPeriod: item.locking_period / 30 / 86400,
                        actionType: MainPoolActionType[item.action_type],
                        releaseDate: `${item.action_type == MainPoolActionType.Staking ? (new Date(item.end_time * 1000)).toLocaleString() : '-'}`,
                        actionTime: `${new Date(item.created_at).toLocaleString()}`
                    }

                    return tableRowData
                })
            )

            setLoading(false)
        } catch (err) {
            setLoading(false)
        }
    }

    useEffect(() => {
        (async () => {
            await initialize()
        })()
    }, [])

    return (
        <Skeleton loading={isLoading && !tableData} active>
            <>
                <div className="title mt-3">MainPool Status</div>
                <Table
                    columns={columns as any}
                    dataSource={tableData}
                    scroll={{ x: 1000 }}
                    loading={isLoading}
                />
            </>
        </Skeleton>
    );
}

export default MainPoolStatusTable;