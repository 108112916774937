import React, { FC, ReactNode, useMemo, useCallback } from 'react';
import { BrowserRouter, Route, Routes, Navigate, Outlet, } from "react-router-dom";
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import { PhantomWalletAdapter, SlopeWalletAdapter, SolflareWalletAdapter } from '@solana/wallet-adapter-wallets';
import { clusterApiUrl } from '@solana/web3.js';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import { BackTop } from 'antd';

import Navbar from './components/Navbar';
import Home from './components/Home';
import Vesting from './components/Vesting';
import MainPool from './components/MainPool';
import MerchantPool from './components/MerchantPool';
import Admin from './components/Admin';

require('./App.scss');
require('@solana/wallet-adapter-react-ui/styles.css');

const App: FC = () => {
    return (
        <Context>
            <Content />
        </Context>
    );
};
export default App;

const Context: FC<{ children: ReactNode }> = ({ children }) => {
    // The network can be set to 'devnet', 'testnet', or 'mainnet-beta'.
    const network = WalletAdapterNetwork.Devnet;

    // You can also provide a custom RPC endpoint.
    const endpoint = useMemo(() => clusterApiUrl(network), [network]);

    const wallets = useMemo(
        () => [
            /**
             * Select the wallets you wish to support, by instantiating wallet adapters here.
             *
             * Common adapters can be found in the npm package `@solana/wallet-adapter-wallets`.
             * That package supports tree shaking and lazy loading -- only the wallets you import
             * will be compiled into your application, and only the dependencies of wallets that
             * your users connect to will be loaded.
             */
            new PhantomWalletAdapter(),
            new SlopeWalletAdapter(),
            new SolflareWalletAdapter()
        ],
        []
    );

    return (
        <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={wallets}>
                <WalletModalProvider>{children}</WalletModalProvider>
                <ToastContainer autoClose={1500} />
            </WalletProvider>
        </ConnectionProvider>
    );
};


const AppLayout = () => {
    const particlesInit = useCallback(async (engine: any) => {
        await loadFull(engine);
    }, []);

    const particlesLoaded = useCallback(async (container: any | undefined) => {
        // console.log(container)
    }, []);

    return (
        <>
            <Navbar />
            <div className='App'>
                <div className='bindapp-main'>
                    <Particles
                        id="tsparticles"
                        init={particlesInit}
                        loaded={particlesLoaded}
                        style={{ zIndex: -1 }}
                        params={{
                            particles: {
                                number: {
                                    value: 80,
                                },
                                color: {
                                    value: "#828282"
                                },
                                line_linked: {
                                    enable: true,
                                    opacity: 0.05
                                },
                                collisions: {
                                    enable: true,
                                },
                                move: {
                                    direction: "none",
                                    enable: true,
                                    outModes: {
                                        default: "bounce",
                                    },
                                    random: false,
                                    speed: 1,
                                    straight: false,
                                },
                                size: {
                                    value: 2
                                },
                                opacity: {
                                    anim: {
                                        enable: true,
                                        speed: 2,
                                        opacity_min: 0.02,
                                    }
                                }
                            },
                            interactivity: {
                                events: {
                                    onclick: {
                                        enable: true,
                                        mode: "push"
                                    }
                                },
                                modes: {
                                    push: {
                                        particles_nb: 1
                                    }
                                }
                            },
                            retina_detect: true
                        }}
                    />
                    <div className='gradient-bg'></div>
                    <Outlet />
                </div>
            </div>
            <BackTop />
        </>
    );
};

const Content: FC = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<AppLayout />} >
                    <Route path="/" element={<Navigate to="/home" />} />
                    <Route path="/home" element={<Home />} />
                    <Route path="/vesting" element={<Vesting />} />
                    <Route path="/main-pool" element={<MainPool />} />
                    <Route path="/merchant-pool" element={<MerchantPool />} />
                    <Route path="/admin" element={<Admin />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
};
