import React from 'react';
import './index.scss';

const Home = () => {
    return (
        <div className='home-page'>
            <h2 className='home-page-title'>WELCOME TO BIND.COM</h2>
        </div>
    );
};

export default Home;