import axios from 'axios';

const axiosInstance = () => {
    const defaultOptions = {
        baseURL: "https://chain-api.thebind.uk/v1",
        headers: {
            'Content-Type': 'application/json',
        },
    };

    // Create instance
    let instance = axios.create(defaultOptions);
    return instance;
};

export default axiosInstance();