import React, { useEffect, useState } from 'react';
import { Skeleton, Button, Form, Input, Spin } from 'antd';
import { CreditCardOutlined } from '@ant-design/icons';
import { useWallet } from "@solana/wallet-adapter-react"; import {
    TOKEN_PROGRAM_ID,
    getAssociatedTokenAddress
} from "@solana/spl-token";
import { Cluster, clusterApiUrl, Connection, Keypair, LAMPORTS_PER_SOL, PublicKey, Transaction } from "@solana/web3.js";
import { Program, Provider } from "@project-serum/anchor";
import { Wallet } from '@project-serum/anchor/dist/cjs/provider';
import * as anchor from "@project-serum/anchor";
import stakingIdl from "../../idl/staking-idl.json";
import axios from 'axios';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from "react-redux";
import { CombinedReducer } from '../../store';
import User from '../../interfaces/User';
import MainPool from '../../interfaces/MainPool';
import MerchantPoolCreateModal from './MerchantPoolCreateModal';
import MerchantPoolsTable, { MerchantPoolStatusEnum } from './MerchantPoolsTable';

import "./index.scss";
import fetchClient from '../../utils/fetchClient';
import { Row } from 'react-bootstrap';
import {
    REACT_APP_BIND_TOKEN_MINT_ADDRESS,
    REACT_APP_SOLANA_NETWORK
} from '../../utils/constants';

interface IValues {
    merchantName: string
}


const bindTokenMint = new PublicKey(REACT_APP_BIND_TOKEN_MINT_ADDRESS!);
const programID = new PublicKey(stakingIdl.metadata.address);
const network = clusterApiUrl(REACT_APP_SOLANA_NETWORK as Cluster);
const connection = new Connection(network, "processed");

const MerchantPool = () => {
    const [form] = Form.useForm();
    const wallet = useWallet();
    const dispatch = useDispatch();
    const user = useSelector<CombinedReducer, User>((state) => state.user);
    const mainPool = useSelector<CombinedReducer, MainPool>((state) => state.mainPool);

    const [isLoading, setLoading] = useState<boolean>(false);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [merchantInfo, setMerchantInfo] = useState<any>(null);
    const [tokenBalance, setTokenBalance] = useState<number>(0);
    const [mainPoolKey, setMainPoolKey] = useState<PublicKey | null>(null);

    async function getProvider() {
        const provider = new Provider(connection, wallet as Wallet, { commitment: 'processed' });
        return provider;
    }


    const initialize = async (isInit: boolean) => {
        try {
            if (isInit)
                setIsProcessing(true);

            const merchantInfo = (await fetchClient.get("/merchant/get_by_merchant")).data.data;
            if (merchantInfo) {
                setMerchantInfo(merchantInfo);
            }
            setIsProcessing(false);
        } catch (_) {
            setIsProcessing(false);
        }
    }

    const handleRegisterMerchant = async (values: IValues) => {
        try {
            const data = {
                merchant_name: values.merchantName,
            }
            const merchantInfo = (await fetchClient.post("/merchant/register_merchant", data)).data.data;
            setMerchantInfo(merchantInfo);
            toast.success("Successed to register merchant")
        } catch (e: any) {
            if (e?.response?.data && e?.response?.data?.message) {
                toast.error(e?.response?.data?.message);
            } else {
                toast.error("Failed to register merchant");
            }
        }
    };

    const handleModal = async (status: boolean) => {
        if (!wallet.publicKey) {
            toast.warn("Please connect your wallet");
            return;
        }

        setShowModal(status);
    }

    const handleCreateMerchantPool = async (lockingTime: number, neededTokenAmount: number) => {
        try {
            if (mainPoolKey == null) {
                toast.warn("MainPool isn't initialized yet");
                return;
            }

            setIsProcessing(true);
            const provider = await getProvider();
            const program = new Program(stakingIdl as anchor.Idl, programID, provider);
            const merchantKeyPair = new Keypair();
            const merchantPubkey = merchantKeyPair.publicKey;

            const tx = await program.rpc.initializeMerchantPool(
                new anchor.BN(neededTokenAmount * LAMPORTS_PER_SOL),
                new anchor.BN(lockingTime),
                {
                    accounts: {
                        pool: mainPoolKey,
                        merchant: merchantPubkey,
                        owner: provider.wallet.publicKey,
                        systemProgram: anchor.web3.SystemProgram.programId,
                    },
                    signers: [merchantKeyPair],
                }
            );
            console.log("tx: ", tx);

            toast.success("Success to create a new merchant pool");
            setIsProcessing(false);
        } catch (err: any) {
            console.log("err: ", err);
            toast.error("Failed to create a new merchant pool");
            setIsProcessing(false);
        }
    }

    const saveActions = async (merchantPoolId: number) => {
        let data: any = {
            user: user?.id,
            merchant_pool: merchantPoolId,
            successed_status: true
        };

        await fetchClient.post(
            "/merchant_pool_actions/create",
            data
        );
    }

    const handleStakeTokensToMerchantPool = async (
        merchantAddress: string,
        merchantPoolAddress: string,
        stakingAmount: number,
        merchantPoolId: number
    ) => {
        if (!wallet?.publicKey) {
            toast.warn(`Please connect wallet`);
            return;
        }

        if (merchantAddress == wallet.publicKey?.toString()) {
            toast.warn(`Owner of this merchant pool can't stake tokens to this pool`);
            return;
        }

        if (stakingAmount > tokenBalance) {
            toast.warn(`Insufficient token balance`);
            return;
        }

        try {
            setIsProcessing(true)
            const provider = await getProvider();
            const program = new Program(stakingIdl as anchor.Idl, programID, provider);
            const merchantPoolPubkey = new PublicKey(merchantPoolAddress);

            const [
                merchantUserAccount,
                merhantUserAccountBump,
            ] = await anchor.web3.PublicKey.findProgramAddress(
                [
                    provider.wallet.publicKey.toBuffer(),
                    merchantPoolPubkey.toBuffer(),
                    mainPoolKey!.toBuffer()
                ],
                program.programId
            );

            const transaction = new Transaction();
            if (await provider.connection.getAccountInfo(merchantUserAccount)) {
                toast.warn("You can stake only once");
                setIsProcessing(false);
                return;
            }

            transaction.add(
                program.instruction.createMerchantUser(
                    merhantUserAccountBump,
                    {
                        accounts: {
                            pool: mainPoolKey!,
                            merchant: merchantPoolPubkey,
                            merchantUser: merchantUserAccount,
                            owner: provider.wallet.publicKey,
                            systemProgram: anchor.web3.SystemProgram.programId,
                        },
                    }
                )
            );

            const mainPoolAccountInfo = await program.account.pool.fetch(mainPoolKey!);
            const [poolSigner] = await anchor.web3.PublicKey.findProgramAddress(
                [mainPoolKey!.toBuffer()],
                program.programId
            );
            const userWalletAta = await getAssociatedTokenAddress(bindTokenMint, provider.wallet.publicKey);

            transaction.add(
                program.instruction.stakeTokenToMerchant(
                    {
                        accounts: {
                            pool: mainPoolKey!,
                            merchant: merchantPoolPubkey,
                            stakingVault: mainPoolAccountInfo.stakingVault,
                            merchantUser: merchantUserAccount,
                            owner: provider.wallet.publicKey,
                            stakeFromAccount: userWalletAta,
                            poolSigner,
                            clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
                            tokenProgram: TOKEN_PROGRAM_ID,
                        },
                    }
                )
            );

            const tx = await wallet.sendTransaction(transaction, connection);
            console.log("tx: ", tx);

            try {
                await saveActions(merchantPoolId);
                await initialize(false);
            } catch (e: any) {
                if (e?.response?.data && e?.response?.data?.message) {
                    toast.error(e?.response?.data?.message);
                } else {
                    toast.error("Internal server error");
                }
                setIsProcessing(false);
                return;
            }

            toast.success(`Successed}`);
            setIsProcessing(false);
        } catch (err) {
            console.log("err", err);
            toast.error(`Failed`);
            setIsProcessing(false);
        }
    }

    useEffect(() => {
        (async () => {
            await initialize(true);
        })()
    }, [wallet]);

    useEffect(() => {
        setTokenBalance(Number(user?.balance));
    }, [user?.balance]);

    useEffect(() => {
        if (mainPool?.mainPoolPubkey) {
            setMainPoolKey(new PublicKey(mainPool?.mainPoolPubkey));
        }
    }, [mainPool?.mainPoolPubkey]);

    return (
        <div className='page merchantpool-page'>
            <div className='merchantpool-page-card'>
                <Spin spinning={isProcessing}>
                    <div className='title'>
                        {
                            !merchantInfo ? (
                                "Request for registering a merchant"
                            ) : (
                                "Merchant Info"
                            )
                        }
                    </div>
                    <div className='merchant'>
                        {
                            !merchantInfo ? (
                                <Form
                                    form={form}
                                    name="horizontal_login"
                                    layout="inline"
                                    className="justify-content-start"
                                    onFinish={handleRegisterMerchant}
                                >
                                    <Form.Item
                                        name="merchantName"
                                        className="mb-2"
                                        style={{ minWidth: '42%' }}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input merchant name!',
                                            },
                                        ]}
                                    >
                                        <Input prefix={<CreditCardOutlined className="site-form-item-icon" />} placeholder="Merchant Name" />
                                    </Form.Item>
                                    <Form.Item shouldUpdate>
                                        {() => (
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                                disabled={
                                                    !form.isFieldsTouched(true) ||
                                                    !!form.getFieldsError().filter(({ errors }) => errors.length).length
                                                }
                                            >
                                                Submit
                                            </Button>
                                        )}
                                    </Form.Item>
                                </Form>
                            ) : (
                                <div className="info-data text-start">
                                    <Row>
                                        <span><strong>Merchat Name: </strong>{merchantInfo?.merchant_name}</span>
                                    </Row>
                                    <Row>
                                        <span><strong>Total merchant pools: </strong>{merchantInfo?.total_pool_count}</span>
                                    </Row>
                                    <Row>
                                        <span><strong>Active merchant pools: </strong>{merchantInfo?.live_pool_count}</span>
                                    </Row>
                                    <Row>
                                        <span><strong>approved_status: </strong>{merchantInfo?.approved_status ? 'Approved' : 'Not Approved'}</span>
                                    </Row>
                                </div>
                            )
                        }
                    </div>
                </Spin>
            </div>
        </div>
    );
};

export default MerchantPool;