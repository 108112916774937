import { useState, useEffect } from 'react'
import { toast } from 'react-toastify';
import { Table, Input, Button, Space, Skeleton, Tooltip, Statistic } from 'antd';
import Highlighter from 'react-highlight-words';
import {
    SearchOutlined,
    DeliveredProcedureOutlined
} from '@ant-design/icons';
import { useSelector } from 'react-redux';

import { useWallet } from "@solana/wallet-adapter-react";
import { Cluster, clusterApiUrl, Connection, LAMPORTS_PER_SOL, PublicKey, Transaction } from "@solana/web3.js";
import { Program, Provider } from "@project-serum/anchor";
import { Wallet } from '@project-serum/anchor/dist/cjs/provider';
import * as anchor from "@project-serum/anchor";
import stakingIdl from "../../../../idl/staking-idl.json";
import convertDate from '../../../../utils/convertDate';
import { CombinedReducer } from '../../../../store';
import User from '../../../../interfaces/User';
import axiosInstance from '../../../../utils/axiosInstance';

import "./index.scss"

export enum MerchantPoolStatusEnum {
    Closed,
    Active
}

let searchInput: any;

const { Countdown } = Statistic;

const MerchantPoolsTable = (props: any) => {
    const user = useSelector<CombinedReducer, User>((state) => state.user);

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('')
    const [isLoading, setLoading] = useState(false)
    const [isProcessing, setIsProcessing] = useState(false)
    const [tableData, setTableData] = useState<any>()

    const wallet = useWallet();

    const getColumnSearchProps = (dataIndex: any) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value: any, record: any) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: (visible: any) => {
            if (visible) {
                setTimeout(() => searchInput.select(), 100);
            }
        },
        render: (text: any) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
        confirm();
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
    };

    const handleReset = (clearFilters: any) => {
        clearFilters();
        setSearchText('')
    };

    const columns = [
        {
            title: 'MerchantId',
            dataIndex: 'merchantId',
            key: 'merchantId',
            responsive: ["sm"],
            ...getColumnSearchProps('merchantId'),
            render: (merchantId: string) => (
                <Tooltip placement="topLeft" title={merchantId}>
                    {String(merchantId).substring(0, 10) + "..."}
                </Tooltip>
            ),
        },
        {
            title: 'MerchantPoolId',
            dataIndex: 'merchantPoolId',
            key: 'merchantPoolId',
            responsive: ["sm"],
            ...getColumnSearchProps('merchantPoolId'),
            render: (merchantPoolId: string) => (
                <Tooltip placement="topLeft" title={merchantPoolId}>
                    {String(merchantPoolId).substring(0, 10) + "..."}
                </Tooltip>
            ),
        },
        {
            title: 'UserCount',
            dataIndex: 'userCount',
            key: 'userCount',
            responsive: ["sm"],
            ...getColumnSearchProps('userCount'),
        },
        {
            title: 'StakedTokenAmount',
            dataIndex: 'stakedTokenAmount',
            key: 'stakedTokenAmount',
            responsive: ["sm"],
            ...getColumnSearchProps('stakedTokenAmount'),
        },
        // {
        //     title: 'TokenAmountNeedToStake',
        //     dataIndex: 'tokenAmountNeedToStake',
        //     key: 'tokenAmountNeedToStake',
        //     responsive: ["sm"],
        //     ...getColumnSearchProps('tokenAmountNeedToStake'),
        // },
        {
            title: 'LockingPeriod',
            dataIndex: 'lockingPeriod',
            key: 'lockingPeriod',
            responsive: ["sm"],
            ...getColumnSearchProps('lockingPeriod')
        },
        {
            title: 'ReleaseDate',
            dataIndex: 'releaseDate',
            key: 'releaseDate',
            responsive: ["sm"],
            ...getColumnSearchProps('releaseDate'),
        },
        {
            title: 'TimeRemaining',
            dataIndex: 'timeRemaining',
            key: 'timeRemaining',
            responsive: ["sm"],
            render: (timeRemaining: any, record: any) => (
                <Countdown value={timeRemaining} format="MM:DD:HH:mm:ss" />
            ),

        },
    ];

    const initialize = async (isInit: boolean) => {
        try {
            if (isInit)
                setLoading(true);

            const config = {
                params: {
                    merchant_pool_status: "active",
                },
            };

            const merchantPools = (await axiosInstance.get("/merchant_pool/get_merchant_pools", config)).data.data;
            const tableData = await Promise.all(
                merchantPools.map(async (item: any, index: any) => {
                    const tableRowData = {
                        key: `${index}`,
                        merchantId: item.merchant.merchant_id,
                        merchantPoolId: item.merchant_pool_id,
                        userCount: item.merchant_pool_user_count,
                        stakedTokenAmount: item.merchant_pool_user_count * item.staking_amount,
                        tokenAmountNeedToStake: item.staking_amount,
                        lockingPeriod: await convertDate(item.merchant_pool_locking_time),
                        releaseDate: `${new Date(new Date(item.created_at).getTime() + item.merchant_pool_locking_time * 1000).toLocaleString()}`,
                        timeRemaining: new Date(item.created_at).getTime() + item.merchant_pool_locking_time * 1000,
                        merchantPoolStatus: MerchantPoolStatusEnum[item.merchant_pool_status]
                    }

                    return tableRowData;
                })
            );

            setTableData(tableData);

            setLoading(false);
        } catch (err) {
            console.log("err", err);
            setLoading(false);
        }
    }

    useEffect(() => {
        (async () => {
            await initialize(true)
        })()
    }, [user])

    return (
        <Skeleton loading={isLoading} active>
            <Table
                className='staked-by-admin'
                columns={columns! as any}
                dataSource={tableData}
                scroll={{ x: 1000 }}
                loading={isProcessing}
            />
        </Skeleton>
    );
}

export default MerchantPoolsTable;