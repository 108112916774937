import { useState, useEffect } from 'react'
import { Table, Input, Button, Space, Skeleton, Tooltip, Switch, Popconfirm, InputNumber } from 'antd';
import Highlighter from 'react-highlight-words';
import {
    SearchOutlined,
    InfoCircleOutlined,
    UserOutlined,
    UndoOutlined,
    SketchOutlined
} from '@ant-design/icons';
import { toast } from 'react-toastify';
import { useWallet } from "@solana/wallet-adapter-react";
import { TOKEN_PROGRAM_ID, getAssociatedTokenAddress } from "@solana/spl-token";
import { Cluster, clusterApiUrl, Connection, LAMPORTS_PER_SOL, PublicKey } from "@solana/web3.js";
import { Program, Provider } from "@project-serum/anchor";
import { Wallet } from '@project-serum/anchor/dist/cjs/provider';
import * as anchor from "@project-serum/anchor";
import vestingIdl from "../../../../idl/vesting-idl.json";
import fetchClient from '../../../../utils/fetchClient';
import {
    REACT_APP_BIND_TOKEN_MINT_ADDRESS,
    REACT_APP_SOLANA_NETWORK
} from '../../../../utils/constants';

import "./index.scss"
import axios from 'axios';


const bindTokenMint = new PublicKey(REACT_APP_BIND_TOKEN_MINT_ADDRESS!);
const network = clusterApiUrl(REACT_APP_SOLANA_NETWORK as Cluster);
const programID = new PublicKey(vestingIdl.metadata.address);
const connection = new Connection(network, "processed");

let searchInput: any;

const VestingStatusTable = (props: any) => {
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('')
    const [isLoading, setLoading] = useState(false)
    const [isProcessing, setIsProcessing] = useState(false)
    const [tableData, setTableData] = useState<any>()
    const [amount, setAmount] = useState(0)

    const {
        investors,
        contractOwner
    } = props

    const wallet = useWallet();

    async function getProvider() {
        const provider = new Provider(connection, wallet as Wallet, { commitment: 'processed' });
        return provider;
    }

    const getColumnSearchProps = (dataIndex: any) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value: any, record: any) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: (visible: any) => {
            if (visible) {
                setTimeout(() => searchInput.select(), 100);
            }
        },
        render: (text: any) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
        confirm();
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
    };

    const handleReset = (clearFilters: any) => {
        clearFilters();
        setSearchText('')
    };

    const columns = [
        {
            title: 'InvestorId',
            dataIndex: 'userId',
            key: 'userId',
            responsive: ["sm"],
            ...getColumnSearchProps('userId'),
            render: (userId: any) => (
                <Tooltip placement="topLeft" title={userId}>
                    {String(userId).substring(0, 10) + "..."}
                </Tooltip>
            ),
        },
        {
            title: 'TotalAmount',
            dataIndex: 'totalAmount',
            key: 'totalAmount',
            responsive: ["sm"],
            ...getColumnSearchProps('totalAmount'),
            render: (totalAmount: any, record: any) => {
                return (
                    record.claimedCount > 0 ? (
                        totalAmount
                    ) : (
                        <Popconfirm
                            placement="topLeft"
                            title={
                                <>
                                    Are you sure to add tokens to this investor?
                                    <InputNumber
                                        placeholder="Enter amount"
                                        className="d-flex w-100"
                                        min={0}
                                        value={amount}
                                        prefix={<SketchOutlined className="site-form-item-icon" />}
                                        onChange={e => setAmount(e)}
                                    />
                                </>
                            }
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => handleAddTokens(record.userId, amount, record.revoked, record.claimedCount)}
                            onVisibleChange={status => { status && setAmount(0) }}
                        >
                            <Tooltip placement="topLeft" title={"Click to add tokens"}>
                                <a className='table-action active'>
                                    {totalAmount}
                                </a>
                            </Tooltip>
                        </Popconfirm>
                    )
                )
            }
        },
        {
            title: 'ClaimedAmount',
            dataIndex: 'claimedAmount',
            key: 'claimedAmount',
            responsive: ["sm"],
            ...getColumnSearchProps('claimedAmount'),
        },
        {
            title: 'RemainedAmount',
            dataIndex: 'remainedAmount',
            key: 'remainedAmount',
            responsive: ["sm"],
            ...getColumnSearchProps('remainedAmount'),
        },
        {
            title: 'ClaimedCount',
            dataIndex: 'claimedCount',
            key: 'claimedCount',
            responsive: ["sm"],
            ...getColumnSearchProps('claimedCount'),
        },
        {
            title: 'Revoke',
            responsive: ["sm"],
            align: 'center',
            render: (text: any, record: any) => {
                return (
                    record.revoked ? (
                        "Revoked"
                    ) : (
                        <Popconfirm
                            placement="topLeft"
                            title={
                                <>
                                    Are you sure to revoke?
                                </>
                            }
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => handleRevoke(record.userId, record.revoked)}
                        >
                            <a className='justify-content-center table-action active'>
                                <UndoOutlined />
                            </a>
                        </Popconfirm>
                    )
                )
            },
        },
        {
            title: 'Approved',
            dataIndex: 'approved',
            key: 'approved',
            align: 'center',
            width: 'auto',
            responsive: ["sm"],
            render: (approved: any, record: any) => {
                return (
                    <Switch
                        size="small"
                        checked={approved == true}
                        onChange={e => handleManageAccount(e, approved, record.key, record.userId)}
                    />
                )
            },
        },
    ];

    const handleManageAccount = async (nextStatus: any, currentStatus: any, index: any, userId: any) => {
        if (nextStatus == currentStatus) {
            toast.warn(`Current account was already ${nextStatus ? 'approved' : 'not approved'}`);
            setIsProcessing(false);
            return;
        }

        try {
            setIsProcessing(true);

            await fetchClient.post(
                "/vesting_info/manage_account",
                {
                    user_id: userId,
                    next_status: nextStatus
                }
            );

            await initialize(false);
            toast.success("Successed");

            setIsProcessing(false);
        } catch (e: any) {
            console.log(e)
            setIsProcessing(false);
            if (e?.response?.data && e?.response?.data?.message) {
                toast.error(e?.response?.data?.message);
            } else if (e?.response?.data && e?.response?.data?.detail) {
                toast.error(e?.response?.data?.detail);
            } else {
                toast.error("Failed. Try again.");
            }
        }
    }

    const handleRevoke = async (userId: any, revoked: any) => {
        if (revoked) {
            toast.warn("Already revoked");
            return;
        }

        try {
            setIsProcessing(true);
            await fetchClient.post(
                "/vesting_info/revoke_account",
                {
                    user_id: userId,
                }
            );

            await initialize(false)

            toast.success("Successed");
            setIsProcessing(false);
        } catch (e: any) {
            console.log("e", e)
            setIsProcessing(false);
            if (e?.response?.data && e?.response?.data?.message) {
                toast.error(e?.response?.data?.message);
            } else if (e?.response?.data && e?.response?.data?.detail) {
                toast.error(e?.response?.data?.detail);
            } else {
                toast.error("Failed. Try again.");
            }
        }
    }

    const handleAddTokens = async (userId: any, amount: any, revoked: any, claimedCount: any) => {
        if (revoked) {
            toast.warn("You can't add tokens. To add tokens, you should have to make 'Approved' button active");
            return;
        }

        if (claimedCount > 0) {
            toast.warn("You can't add tokens because this investor already claimed some amount of tokens");
            return;
        }

        if (amount <= 0) {
            toast.warn("Please fill in amount");
            return;
        }

        try {
            setIsProcessing(true);

            await fetchClient.post(
                "/vesting_info/add_tokens_to_account",
                {
                    user_id: userId,
                    amount
                }
            );

            await initialize(false);

            toast.success("Successed");
            setIsProcessing(false);
        } catch (e: any) {
            console.log("addToken err", e)
            setIsProcessing(false);
            if (e?.response?.data && e?.response?.data?.message) {
                toast.error(e?.response?.data?.message);
            } else if (e?.response?.data && e?.response?.data?.detail) {
                toast.error(e?.response?.data?.detail);
            } else {
                toast.error("Failed. Try again.");
            }
        }
    }

    const initialize = async (isInit: boolean) => {
        try {
            if (isInit)
                setLoading(true)
            const provider = await getProvider();
            const program = new Program(vestingIdl as anchor.Idl, programID, provider);

            const tableData = await Promise.all(
                investors.map(async (investor: any, index: any) => {
                    const investorPubkey = new PublicKey(investor?.user?.wallet_pubkey)
                    const investorTokenAccount = await getAssociatedTokenAddress(bindTokenMint, investorPubkey);

                    const [vestingAccount] = await PublicKey.findProgramAddress(
                        [investorTokenAccount.toBuffer()],
                        program.programId
                    );

                    const vestingAccountObject = await program.account.vestingAccount.fetch(vestingAccount);

                    const tableRowData = {
                        key: `${index}`,
                        userId: investor?.user?.user_id,
                        investorPubkey: `${investorPubkey.toString()}`,
                        totalAmount: `${vestingAccountObject.totalDepositedAmount / LAMPORTS_PER_SOL}`,
                        claimedAmount: `${vestingAccountObject.releasedAmount / LAMPORTS_PER_SOL}`,
                        remainedAmount: `${(vestingAccountObject.totalDepositedAmount - vestingAccountObject.releasedAmount) / LAMPORTS_PER_SOL}`,
                        claimedCount: `${vestingAccountObject.claimedCount}`,
                        revoked: vestingAccountObject.revoked,
                        approved: vestingAccountObject.approved,
                    }

                    return tableRowData
                })
            )

            setTableData(tableData)

            setLoading(false)
        } catch (err) {
            console.log("err", err)
            setLoading(false)
        }
    }

    useEffect(() => {
        (async () => {
            await initialize(true)
        })()
    }, [investors.length, contractOwner])

    return (
        <Skeleton loading={isLoading} active>
            <Table
                columns={columns! as any}
                dataSource={tableData}
                scroll={{ x: 1000 }}
                loading={isProcessing}
            />
        </Skeleton>
    );
}

export default VestingStatusTable;