import { combineReducers, createStore } from 'redux'
import User from './interfaces/User'
import MainPool from './interfaces/MainPool'
import userReducer from './reducers/user'
import mainPoolReducer from './reducers/mainpool'

const combined = combineReducers({
    user: userReducer,
    mainPool: mainPoolReducer
})

export interface CombinedReducer {
    user: User,
    mainPool: MainPool
}

export default createStore(combined)