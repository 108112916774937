import { useState, useEffect } from 'react'
import { toast } from 'react-toastify';
import { Table, Input, Button, Space, Skeleton, Switch, Tooltip, Statistic } from 'antd';
import Highlighter from 'react-highlight-words';
import {
    SearchOutlined,
    DeliveredProcedureOutlined
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import axios from 'axios';

import { useWallet } from "@solana/wallet-adapter-react";
import { CombinedReducer } from '../../../../store';
import User from '../../../../interfaces/User';
import fetchClient from '../../../../utils/fetchClient';
import axiosInstance from '../../../../utils/axiosInstance';
import MerchantDetailsModal from './MerchantDetailsModal';

import "./index.scss"

export enum MerchantPoolStatusEnum {
    Closed,
    Active
}

interface IProps {
    merchants: any[]
}

let searchInput: any;

const MerchantTable = (props: IProps) => {
    const user = useSelector<CombinedReducer, User>((state) => state.user);
    const wallet = useWallet();
    const { merchants } = props;

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [tableData, setTableData] = useState<any>();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [currentMerchantId, setCurrentMerchantId] = useState<string>('');

    const getColumnSearchProps = (dataIndex: any) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value: any, record: any) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: (visible: any) => {
            if (visible) {
                setTimeout(() => searchInput.select(), 100);
            }
        },
        render: (text: any) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
        confirm();
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
    };

    const handleReset = (clearFilters: any) => {
        clearFilters();
        setSearchText('')
    };

    const handleShowModal = async (status: boolean, currentMerchantId: string) => {
        setCurrentMerchantId(currentMerchantId)
        setShowModal(status)
    }

    const handleShowDetails = async (merchantId: string) => {
        await handleShowModal(true, merchantId);
    }

    const columns = [
        {
            title: 'MerchantId',
            dataIndex: 'merchantId',
            key: 'merchantId',
            responsive: ["sm"],
            ...getColumnSearchProps('merchantId'),
            render: (merchantId: string, record: any) => {
                return (
                    <a
                        className='table-action active'
                        onClick={() => handleShowDetails(merchantId)}>{merchantId}</a>
                )
            }
        },
        {
            title: 'TotalStakedAmount',
            dataIndex: 'totalStakedAmount',
            key: 'totalStakedAmount',
            responsive: ["sm"],
            ...getColumnSearchProps('totalStakedAmount'),
        },
        {
            title: 'ActivePoolCount',
            dataIndex: 'activePoolCount',
            key: 'activePoolCount',
            responsive: ["sm"],
            ...getColumnSearchProps('activePoolCount'),
        },
        {
            title: 'ClosedPoolCount',
            dataIndex: 'closedPoolCount',
            key: 'closedPoolCount',
            responsive: ["sm"],
            ...getColumnSearchProps('closedPoolCount'),
        },
    ];

    const initialize = async (isInit: boolean) => {
        try {
            if (isInit)
                setLoading(true);

            const tableData = await Promise.all(
                merchants.map(async (item: any, index: any) => {
                    const tableRowData = {
                        key: `${index}`,
                        merchantId: item.merchant_id,
                        totalStakedAmount: item.total_staked_amount.toLocaleString(),
                        totalPoolCount: item.total_pool_count,
                        activePoolCount: item.live_pool_count,
                        closedPoolCount: item.total_pool_count - item.live_pool_count,
                        approved: item.approved_status
                    }

                    return tableRowData;
                })
            );

            setTableData(tableData);

            setLoading(false);
        } catch (err) {
            console.log("err", err);
            setLoading(false);
        }
    }

    const handleApproveMerchant = async (currentStatus: boolean, merchantId: string) => {
        try {
            setIsProcessing(true);

            await fetchClient.post(
                "/merchant/manage_merchant_status",
                {
                    merchant_id: merchantId,
                    current_status: currentStatus
                }
            );

            await initialize(false);
            toast.success("Successed");

            setIsProcessing(false);
        } catch (e: any) {
            console.log(e)
            setIsProcessing(false);
            if (e?.response?.data && e?.response?.data?.message) {
                toast.error(e?.response?.data?.message);
            } else if (e?.response?.data && e?.response?.data?.detail) {
                toast.error(e?.response?.data?.detail);
            } else {
                toast.error("Failed. Try again.");
            }
        }
    }

    useEffect(() => {
        (async () => {
            await initialize(true)
        })()
    }, [user, merchants, merchants.length])

    return (
        <Skeleton loading={isLoading} active>
            <Table
                className='staked-by-admin'
                columns={columns! as any}
                dataSource={tableData}
                scroll={{ x: 1000 }}
                loading={isProcessing}
            />

            <MerchantDetailsModal
                show={showModal}
                currentMerchantId={currentMerchantId}
                handleModal={handleShowModal}
            />
        </Skeleton>
    );
}

export default MerchantTable;