import MainPool, { MainPoolStatusEnum } from '../interfaces/MainPool'

enum MainPoolActionsEnum {
    LOAD_MAIN_POOL = 'LOAD_MAIN_POOL',
    UPDATE_MAIN_POOL_PUBKEY = 'UPDATE_MAIN_POOL_PUBKEY',
    UPDATE_STAKING_VAULT_BALANCE = "UPDATE_STAKING_VAULT_BALANCE",
    UPDATE_REWARD_VAULT_BALANCE = "UPDATE_REWARD_VAULT_BALANCE"
}

interface LoadMainPoolAction {
    type: MainPoolActionsEnum.LOAD_MAIN_POOL,
    payload: MainPool
}

interface UpdateMainPoolPubkey {
    type: MainPoolActionsEnum.UPDATE_MAIN_POOL_PUBKEY,
    payload: string
}

interface UpdateMainPoolStakingVaultBalance {
    type: MainPoolActionsEnum.UPDATE_STAKING_VAULT_BALANCE,
    payload: number
}

interface UpdateMainPoolRewardVaultBalance {
    type: MainPoolActionsEnum.UPDATE_REWARD_VAULT_BALANCE,
    payload: number
}

type MainPoolActions = LoadMainPoolAction | UpdateMainPoolPubkey | UpdateMainPoolStakingVaultBalance | UpdateMainPoolRewardVaultBalance;

const initState: MainPool = {
    mainPoolPubkey: "",
    mainPoolOwner: "",
    rewardVaultBalance: 0,
    stakingVaultBalance: 0,
    totalStakedByBind: 0,
    totalStakedByUsers: 0,
    lockingPeriodOfBehalfStaking: 0,
    userCount: 0,
    merchantCount: 0,
    mainPoolStatus: MainPoolStatusEnum.Paused
};

export default (state: MainPool = initState, action: MainPoolActions): MainPool => {

    switch (action.type) {
        case MainPoolActionsEnum.LOAD_MAIN_POOL: {
            return action.payload;
        }
        case MainPoolActionsEnum.UPDATE_MAIN_POOL_PUBKEY: {
            return { ...state, mainPoolPubkey: action.payload };
        }
        case MainPoolActionsEnum.UPDATE_STAKING_VAULT_BALANCE: {
            return { ...state, stakingVaultBalance: action.payload };
        }
        case MainPoolActionsEnum.UPDATE_REWARD_VAULT_BALANCE: {
            return { ...state, rewardVaultBalance: action.payload };
        }
        default:
            return state;
    }
}