import React, { useEffect, useState } from 'react';
import { Spin, Statistic } from 'antd';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios';
import bs58 from 'bs58';

import { useWallet } from "@solana/wallet-adapter-react";
import {
    TOKEN_PROGRAM_ID,
    getAssociatedTokenAddress,
    createAssociatedTokenAccountInstruction
} from "@solana/spl-token";
import { Cluster, clusterApiUrl, Connection, LAMPORTS_PER_SOL, PublicKey, Transaction, Keypair } from "@solana/web3.js";
import { Program, Provider } from "@project-serum/anchor";
import { Wallet } from '@project-serum/anchor/dist/cjs/provider';
import * as anchor from "@project-serum/anchor";
import vestingIdl from "../../idl/vesting-idl.json";
import fetchClient from '../../utils/fetchClient';
import { CombinedReducer } from '../../store';
import User from '../../interfaces/User';
import {
    REACT_APP_BIND_TOKEN_MINT_ADDRESS,
    REACT_APP_SOLANA_NETWORK
} from '../../utils/constants';

import './index.scss';

const bindTokenMint = new PublicKey(REACT_APP_BIND_TOKEN_MINT_ADDRESS!);
const network = clusterApiUrl(REACT_APP_SOLANA_NETWORK as Cluster);
const programID = new PublicKey(vestingIdl.metadata.address);
const connection = new Connection(network, "processed");
const REACT_APP_WITHDRAW_PERIOD = 120;

const Vesting = () => {
    const { Countdown } = Statistic;
    const dispatch = useDispatch();
    const user = useSelector<CombinedReducer, User>((state) => state.user);

    const [isRegistered, setRegistered] = useState(false);
    const [isGetUpfront, setIsGetUpfront] = useState(true);
    const [vestedAmount, setVestedAmount] = useState(0);
    const [vestedAt, setVestedAt] = useState(0);
    const [remainedTime, setRemainedTime] = useState(0);
    const [isApproved, setIsApproved] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [isProcessing, setIsProcessing] = useState(false);

    const wallet = useWallet();

    async function getProvider() {
        const provider = new Provider(connection, wallet as Wallet, { commitment: 'processed' });
        return provider;
    }

    const initialize = async () => {
        try {
            setLoading(true);
            const provider = await getProvider();
            const program = new Program(vestingIdl as anchor.Idl, programID, provider);

            let investorPubkey = new PublicKey(provider.wallet.publicKey)
            let investorTokenAccount = await getAssociatedTokenAddress(bindTokenMint, investorPubkey);

            const [vestingAccount] = await PublicKey.findProgramAddress(
                [investorTokenAccount.toBuffer()],
                program.programId
            );

            const rsp = await program.account.vestingAccount.fetch(vestingAccount);
            let slot = await connection.getSlot();
            let currentTime = await connection.getBlockTime(slot);
            const timeleft = REACT_APP_WITHDRAW_PERIOD - (currentTime! - rsp?.withdrawTs?.toNumber());

            setRegistered(true);
            setIsGetUpfront(rsp.isGetUpfront);
            setIsApproved(rsp.approved);
            setVestedAt(rsp?.withdrawTs?.toNumber());
            setRemainedTime(timeleft);
            setVestedAmount((rsp.totalDepositedAmount.toNumber() - rsp.releasedAmount.toNumber()) / LAMPORTS_PER_SOL);
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    }

    const handleUpfront = async () => {
        if (isGetUpfront) {
            toast.info("You already received upfront");
            return;
        }

        if (!isApproved) {
            toast.warn("You are not allowed to receive tokens");
            return;
        }

        if (vestedAmount <= 0) {
            toast.info("You already received all tokens");
            return;
        }

        try {
            setIsProcessing(true);
            setRemainedTime(0);

            await fetchClient.post(
                "/vesting_info/upfront_token",
            );

            const provider = await getProvider();
            const program = new Program(vestingIdl as anchor.Idl, programID, provider);

            let investorPubkey = new PublicKey(provider.wallet.publicKey);
            let investorTokenAccount = await getAssociatedTokenAddress(bindTokenMint, investorPubkey);

            const [vestingAccount] = await PublicKey.findProgramAddress(
                [investorTokenAccount.toBuffer()],
                program.programId
            );

            toast.success("Successed");

            setIsGetUpfront(true);

            const rsp = await program.account.vestingAccount.fetch(vestingAccount);
            const currentTokenBalance = (await provider.connection.getTokenAccountBalance(investorTokenAccount))?.value?.uiAmount || 0;
            dispatch({ type: "UPDATE_USER_BALANCE", payload: currentTokenBalance });

            setVestedAmount((rsp.totalDepositedAmount.toNumber() - rsp.releasedAmount.toNumber()) / LAMPORTS_PER_SOL);

            setIsProcessing(false);
        } catch (e: any) {
            console.log("e", e)
            setIsProcessing(false);
            if (e?.response?.data && e?.response?.data?.message) {
                toast.error(e?.response?.data?.message);
            } else {
                toast.error("Failed to initialize vesting account");
            }
        }
    }

    const handleWithdraw = async () => {
        if (!wallet.publicKey) {
            toast.warn("Please connect your wallet");
            return;
        }

        if (!isApproved) {
            toast.warn("You are not allowed to receive tokens");
            return;
        }

        if (vestedAmount <= 0) {
            toast.warn("You already received all tokens");
            return;
        }

        let slot = await connection.getSlot();
        let currentTime = await connection.getBlockTime(slot);

        if (!isRegistered) {
            toast.warn("You didn\'t vest tokens.");
            return;
        }

        if (!isGetUpfront) {
            toast.info("Please receive upfront before withdrawing tokens");
            return;
        }

        if (currentTime! < vestedAt + REACT_APP_WITHDRAW_PERIOD) {
            toast.warn("You can\'t withdraw tokens yet.");
            return;
        }

        try {
            setIsProcessing(true);
            setRemainedTime(0);

            await fetchClient.post(
                "/vesting_info/withdraw_token",
            );

            const provider = await getProvider();
            const program = new Program(vestingIdl as anchor.Idl, programID, provider);

            let investorPubkey = new PublicKey(provider.wallet.publicKey);
            let investorTokenAccount = await getAssociatedTokenAddress(bindTokenMint, investorPubkey);

            const [vestingAccount] = await PublicKey.findProgramAddress(
                [investorTokenAccount.toBuffer()],
                program.programId
            );

            let rsp = await program.account.vestingAccount.fetch(vestingAccount);

            toast.success("Successed");

            rsp = await program.account.vestingAccount.fetch(vestingAccount);
            const currentTokenBalance = (await provider.connection.getTokenAccountBalance(investorTokenAccount))?.value?.uiAmount || 0;
            dispatch({ type: "UPDATE_USER_BALANCE", payload: currentTokenBalance });
            setVestedAmount((rsp.totalDepositedAmount.toNumber() - rsp.releasedAmount.toNumber()) / LAMPORTS_PER_SOL);
            setRemainedTime(REACT_APP_WITHDRAW_PERIOD)
            setVestedAt(currentTime!);
            setIsProcessing(false);
        } catch (e: any) {
            console.log("e", e)
            setIsProcessing(false);
            if (e?.response?.data && e?.response?.data?.message) {
                toast.error(e?.response?.data?.message);
            } else {
                toast.error("Failed to withdraw tokens");
            }
        }
    }

    useEffect(() => {
        (async () => {
            await initialize();
        })()
    }, [user]);

    return (
        <div className='vesting-page'>
            <div className='vesting-page-card'>
                <Spin spinning={isLoading || isProcessing}>
                    <div className='title'>Vested Token Amount</div>
                    <div className='token-amount'>{vestedAmount} BIND</div>
                    <Countdown
                        value={Date.now() + remainedTime * 1000}
                        format="DD:HH:mm:ss"
                    />
                    {
                        isGetUpfront ? (
                            <button
                                className='bindapp-btn vesting-btn'
                                onClick={handleWithdraw}
                            >
                                Withdraw
                            </button>
                        ) : (
                            <button
                                className='bindapp-btn vesting-btn'
                                onClick={handleUpfront}
                            >
                                Upfront
                            </button>
                        )
                    }
                </Spin>
            </div>
        </div>
    )
};

export default Vesting;