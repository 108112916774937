import React, { useEffect, useState } from 'react';
import { Skeleton, Spin } from 'antd';
import MerchantPoolsTable from './MerchantPoolsTable';
import MerchantTable from './MerchantTable';

import "./index.scss";
import { Row } from 'react-bootstrap';
import axiosInstance from '../../../utils/axiosInstance';


const MerchantPoolStatusCard = () => {
    const [merchants, setMerchants] = useState<any[]>([]);
    const [totalStakedAmount, setTotalStakedAmount] = useState<number>(0);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    useEffect(() => {
        (async () => {
            try {
                const merchants: any[] = (await axiosInstance.get("/merchant/get_all_merchants")).data.data;
                const totalStakedAmount = merchants.reduce((prev: number, currentObj: any) => prev + currentObj.total_staked_amount, 0);
                setTotalStakedAmount(totalStakedAmount);
                setMerchants(merchants);
            } catch (_) { }
        })()
    }, [])

    return (
        <div className='admin-page-card mb-5'>
            <Skeleton loading={isLoading} active>
                <div className="w-100">
                    <Spin spinning={isProcessing}>
                        <div className="info-data text-start mb-5">
                            <div className='title'>MerchantPool Informaton</div>
                            <Row>
                                <span><strong>Total Staked Amount: </strong>{totalStakedAmount.toLocaleString()}</span>
                            </Row>
                            <Row>
                                <span><strong>Total of awards paid: </strong>{`N/A`}</span>
                            </Row>
                        </div>

                        <div className='title'>Registered Merchants</div>
                        <MerchantTable
                            merchants={merchants}
                        />

                        <div className='title mt-2'>All Active Merchant Pools</div>
                        <MerchantPoolsTable />
                    </Spin>
                </div>
            </Skeleton>
        </div>
    );
};

export default MerchantPoolStatusCard;