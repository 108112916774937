import React from 'react';
import VestingStatus from './VestingStatus';
import MainPoolStatusCard from './MainPoolStatusCard';
import MerchantPoolStatusCard from './MerchantPoolStatusCard';

import './index.scss';

const Admin = () => {
    return (
        <div className='page admin-page'>
            <VestingStatus />
            <MainPoolStatusCard />
            <MerchantPoolStatusCard />
        </div>
    );
};

export default Admin;