import React from 'react';
import { Spin } from 'antd';
import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { toast } from 'react-toastify';
import { AuthModalTypeEnum } from '..';
import './index.scss';

const AuthModal = (props: any) => {
    const [name, setName] = useState<string | null>(null);
    const [email, setEmail] = useState<string | null>(null);
    const [password, setPassword] = useState<string | null>(null);

    const {
        show,
        isProcessing,
        authModalType,
        handleSetAuthModalType,
        handleModal,
        handleLogin,
        handleRegister
    } = props;

    const handleClose = () => {
        setPassword(null);
        handleModal(false);
    };

    const handleSubmit = async () => {
        if (authModalType == AuthModalTypeEnum.Register && !name) {
            toast.warn("Please input name");
            return;
        }

        if (!email) {
            toast.warn("Please input email");
            return;
        }

        if (!password) {
            toast.warn("Please input password");
            return;
        }

        if (authModalType == AuthModalTypeEnum.Login) {
            await handleLogin(email, password);
        } else if (authModalType == AuthModalTypeEnum.Register) {
            await handleRegister(email, password, name);
        }
    }

    return (
        <Modal
            show={show}
            onHide={handleClose}
            centered
            className='bindapp-modal'
        >
            <Spin spinning={isProcessing}>
                <Modal.Header closeButton closeVariant='white'>
                    <Modal.Title>
                        {
                            authModalType == AuthModalTypeEnum.Login ? (
                                "Login"
                            ) : authModalType == AuthModalTypeEnum.Register ? (
                                "Register"
                            ) : (
                                "AuthModal"
                            )
                        }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        {
                            authModalType == AuthModalTypeEnum.Register && (
                                <Form.Group className="mb-3">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        onInput={(e: any) => setName(e?.target?.value)}
                                        value={name ? name : ""}
                                        type="text"
                                        placeholder="Please input name"
                                        autoFocus
                                    />
                                </Form.Group>
                            )
                        }
                        <Form.Group className="mb-3">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                onInput={(e: any) => setEmail(e?.target?.value)}
                                value={email ? email : ""}
                                type="email"
                                placeholder="Please input email"
                                autoFocus
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                onInput={(e: any) => setPassword(e?.target?.value)}
                                value={password ? password : ""}
                                type="password"
                                placeholder="Please input password"
                                autoFocus
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <a
                        href='#'
                        onClick={() => handleSetAuthModalType(
                            authModalType == AuthModalTypeEnum.Login
                                ?
                                AuthModalTypeEnum.Register
                                :
                                AuthModalTypeEnum.Login)
                        }
                    >
                        {
                            authModalType == AuthModalTypeEnum.Login ? (
                                "Sign up"
                            ) : (
                                "Sign in"
                            )
                        }
                    </a>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={() => handleSubmit()}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Spin>
        </Modal>
    );
}

export default AuthModal;