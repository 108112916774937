import { useEffect, useState } from 'react';
import {
    Nav,
    Navbar as BsNavbar,
} from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import axios from "axios";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useWallet } from '@solana/wallet-adapter-react';
import {
    Connection,
    PublicKey,
    clusterApiUrl,
    Cluster,
    LAMPORTS_PER_SOL,
} from "@solana/web3.js";
import {
    getAssociatedTokenAddress,
} from "@solana/spl-token";
import { CombinedReducer } from '../../store';
import User from '../../interfaces/User';
import MainPool from '../../interfaces/MainPool';
import fetchClient from '../../utils/fetchClient';
import AuthModal from './AuthModal';
import {
    REACT_APP_BIND_TOKEN_MINT_ADDRESS,
    REACT_APP_SOLANA_NETWORK,
    BIND_VAULT_PUBKEY
} from '../../utils/constants';
import axiosInstance from '../../utils/axiosInstance';

import './index.scss';

export enum AuthModalTypeEnum {
    Login,
    Register
}

interface IUserInfo {
    id: number,
    name: string,
    email: string,
    wallet_pubkey: string,
    total_staked_amount: number,
    self_staked_amount: number,
    token?: string
}

const bindTokenMint = new PublicKey(REACT_APP_BIND_TOKEN_MINT_ADDRESS!);
const network = clusterApiUrl(REACT_APP_SOLANA_NETWORK as Cluster);
const connection = new Connection(network, "processed");

const Navbar = () => {
    const wallet = useWallet();
    const dispatch = useDispatch();
    const user = useSelector<CombinedReducer, User>((state) => state.user);

    const [isAskedToVerify, setIsAskedToVerify] = useState(false);
    const [authModalType, setAuthModalType] = useState<AuthModalTypeEnum>();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [bindVaultBalance, setBindVaultBalance] = useState<number>(0);
    const [isLoading, setLoading] = useState(true);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    const initialize = async () => {
        try {
            setLoading(true)

            try {
                const token = localStorage.getItem("token");
                if (token) {
                    const userInfo = (await fetchClient.get("/auth/get_auth_user")).data.data;
                    await loadUserInfo(userInfo);
                }
            } catch (_) { }


            try {
                const bindVaultTokenAccount = await getAssociatedTokenAddress(bindTokenMint, new PublicKey(BIND_VAULT_PUBKEY));
                const bindVaultBalance = (await connection.getTokenAccountBalance(bindVaultTokenAccount))?.value?.uiAmount || 0;
                setBindVaultBalance(bindVaultBalance);
            } catch (_) { }

            try {
                const mainPool = await (await axiosInstance.get("/main_pool/get_info")).data.data;

                if (mainPool?.main_pool_pubkey) {
                    const mainPoolPayload: MainPool = {
                        mainPoolPubkey: mainPool?.main_pool_pubkey,
                        mainPoolOwner: mainPool?.main_pool_owner,
                        rewardVaultBalance: mainPool?.reward_vault_balance,
                        stakingVaultBalance: mainPool?.staking_vault_balance,
                        totalStakedByBind: mainPool?.total_staked_by_bind,
                        totalStakedByUsers: mainPool?.total_staked_by_users,
                        lockingPeriodOfBehalfStaking: mainPool?.locking_period_of_behalf_staking,
                        userCount: mainPool?.user_count,
                        merchantCount: mainPool?.merchant_count,
                        mainPoolStatus: mainPool?.main_pool_status
                    }

                    dispatch({ type: "LOAD_MAIN_POOL", payload: mainPoolPayload });
                }
            } catch (_) { }

            setLoading(false);
        } catch (_) {
            setLoading(false);
        }
    }

    const loadUserInfo = async (userInfo: IUserInfo) => {
        if (userInfo) {
            if (userInfo?.token) {
                localStorage.setItem("token", userInfo?.token);
            }
            const userWalletAddress = userInfo?.wallet_pubkey;
            const ownerTokenAccount = await getAssociatedTokenAddress(bindTokenMint, new PublicKey(userWalletAddress));
            let tokenBalance = 0;
            let solBalance = 0;
            try {
                tokenBalance = (await connection.getTokenAccountBalance(ownerTokenAccount))?.value?.uiAmount || 0;
                solBalance = await connection.getBalance(new PublicKey(userWalletAddress));
            } catch (_) { }

            const userInfoPayload: User = {
                id: userInfo?.id,
                isLoggedIn: true,
                publicKey: userInfo?.wallet_pubkey,
                balance: tokenBalance,
                solBalance: (solBalance / LAMPORTS_PER_SOL).toLocaleString(),
                totalStakedAmount: userInfo?.total_staked_amount,
                selfStakedAmount: userInfo?.self_staked_amount
            };
            dispatch({ type: "LOAD_USER", payload: userInfoPayload });
        }
    }

    const handleSetAuthModalType = (authModalType: AuthModalTypeEnum) => {
        setAuthModalType(authModalType);
    }

    const handleShowModal = (status: boolean) => {
        setShowModal(status);
    }

    const handleLogin = async (email: string, password: string) => {
        try {
            setIsProcessing(true);
            const data = { email, password };
            const userInfo = (await axiosInstance.post("/auth/signin", data)).data.data;
            await loadUserInfo(userInfo);
            toast.success("Successed to login");
            setShowModal(false);
            setIsProcessing(false);
        } catch (e: any) {
            setIsProcessing(false);
            if (e?.response?.data && e?.response?.data?.detail) {
                toast.error(e?.response?.data?.detail);
            } else if (e?.response?.data && e?.response?.data?.email) {
                toast.error(e?.response?.data?.email[0]);
            } else if (e?.response?.data && e?.response?.data?.message) {
                toast.error(e?.response?.data?.message);
            } else {
                toast.error("Failed to login");
            }
        }
    }

    const handleRegister = async (email: string, password: string, name: string) => {
        try {
            setIsProcessing(true);
            const data = { email, password, name };
            const userInfo = (await axiosInstance.post("/auth/signup", data)).data.data;
            await loadUserInfo(userInfo);
            toast.success("Successed to register");
            setShowModal(false);
            setIsProcessing(false);
        } catch (e: any) {
            setIsProcessing(false);
            if (e?.response?.data && e?.response?.data?.detail) {
                toast.error(e?.response?.data?.detail);
            } else if (e?.response?.data && e?.response?.data?.email) {
                toast.error(e?.response?.data?.email[0]);
            } else if (e?.response?.data && e?.response?.data?.message) {
                toast.error(e?.response?.data?.message);
            } else {
                toast.error("Failed to register");
            }
        }
    }

    const handleLogout = async () => {
        try {
            await fetchClient.get("/auth/signout");
            localStorage.removeItem("token");
            dispatch({ type: "LOAD_USER", payload: null });
        } catch (e: any) {
            if (e?.response?.data && e?.response?.data?.message) {
                toast.error(e?.response?.data?.message);
            } else {
                toast.error("Failed to logout");
            }
        }
    }

    useEffect(() => {
        (async () => {
            await initialize();
        })()
    }, []);

    return (
        <BsNavbar expand="md" fixed="top" variant="dark" className='pl-5 pr-5'>
            <BsNavbar.Brand href="/" className='logo-nav'>
                <img
                    alt=""
                    src="/logo.svg"
                    width="40"
                    height="40"
                    className="d-inline-block align-top"
                />{' '}
                Bind
            </BsNavbar.Brand>
            <BsNavbar.Toggle aria-controls="basic-BsNavbar-nav" />
            <BsNavbar.Collapse id="basic-BsNavbar-nav">
                <Nav className="me-auto">
                </Nav>

                <Nav className='nav-page-link'>
                    <NavLink to={'/home'}>Home</NavLink>
                    {/* <NavLink to={'/vesting'}>Vesting</NavLink>
                    <NavLink to={'/main-pool'}>Main Pool</NavLink> */}
                    <NavLink to={'/merchant-pool'}>Merchant Pool</NavLink>
                    <NavLink to={'/admin'}>Admin</NavLink>
                </Nav>
                {
                    user?.isLoggedIn && (
                        <>
                            <Nav className='user-balance'>
                                {user?.solBalance} SOL
                            </Nav>
                            <Nav className='user-balance'>
                                {user?.balance.toLocaleString()} BIND
                            </Nav>
                            <Nav className='user-balance'>
                                Bind Vault: {bindVaultBalance.toLocaleString()} BIND
                            </Nav>
                        </>
                    )
                }

                {
                    !user?.isLoggedIn ? (
                        <button
                            className='navbar-custom-button'
                            onClick={() => {
                                setAuthModalType(AuthModalTypeEnum.Login);
                                handleShowModal(true);
                            }}
                        >
                            Sign in
                        </button>
                    ) : (
                        <button
                            className='navbar-custom-button'
                            onClick={handleLogout}
                        >
                            Sign out
                        </button>
                    )
                }
            </BsNavbar.Collapse>

            <AuthModal
                show={showModal}
                isProcessing={isProcessing}
                authModalType={authModalType}
                handleSetAuthModalType={handleSetAuthModalType}
                handleModal={handleShowModal}
                handleLogin={handleLogin}
                handleRegister={handleRegister}
            />
        </BsNavbar>
    );
};

export default Navbar;