const convertDate = async (time: number) => {
    const weekInDay = 7;
    const dayInSecond = 86400;
    const hourInSecond = 3600;
    const minuteInSecond = 60;

    let result = "";
    if ((time / dayInSecond / weekInDay) > 1) {
        result = (time / dayInSecond / weekInDay) + " weeks";
    } else if ((time / dayInSecond) > 1) {
        result = (time / dayInSecond) + " days"
    } else if ((time / hourInSecond) > 1) {
        result = (time / hourInSecond) + " hours"
    } else if ((time / minuteInSecond) > 1) {
        result = (time / minuteInSecond) + " minutes"
    } else {
        result = time + " seconds"
    }

    return result;
}

export default convertDate;