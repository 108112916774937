export enum MainPoolStatusEnum {
    Paused,
    Unpaused
}

export enum MainPoolActionType {
    Staking,
    Unstaking,
    Claiming,
    Restaking
}

export default interface MainPool {
    mainPoolPubkey: string,
    mainPoolOwner: string,
    rewardVaultBalance: number,
    stakingVaultBalance: number,
    totalStakedByUsers: number,
    totalStakedByBind: number,
    lockingPeriodOfBehalfStaking: number,
    userCount: number,
    merchantCount: number,
    mainPoolStatus: MainPoolStatusEnum
}