import { useState, useEffect } from 'react'
import { Table, Input, Button, Space, Skeleton, Tooltip } from 'antd';
import Highlighter from 'react-highlight-words';
import {
    SearchOutlined,
} from '@ant-design/icons';

import { useWallet } from "@solana/wallet-adapter-react";
import MainPool from '../../../../../interfaces/MainPool';
import axiosInstance from '../../../../../utils/axiosInstance';

import "./index.scss"

export enum StakingOnBehalfStatusEnum {
    Staked,
    Unstaked
}

interface IProps {
    mainPoolInfo: MainPool
}

let searchInput: any;

const StakingOnBehalfTable = (props: IProps) => {
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [tableData, setTableData] = useState<any>();

    const { mainPoolInfo } = props

    const wallet = useWallet();

    const getColumnSearchProps = (dataIndex: any) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value: any, record: any) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: (visible: any) => {
            if (visible) {
                setTimeout(() => searchInput.select(), 100);
            }
        },
        render: (text: any) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
        confirm();
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
    };

    const handleReset = (clearFilters: any) => {
        clearFilters();
        setSearchText('')
    };

    const columns = [
        {
            title: 'UserId',
            dataIndex: 'userId',
            key: 'userId',
            responsive: ["sm"],
            fixed: "left",
            ...getColumnSearchProps('userId'),
            render: (userId: any, record: any) => (
                <Tooltip placement="topLeft" title={userId}>
                    {String(userId).substring(0, 20) + "..."}
                </Tooltip>
            ),
        },
        {
            title: 'TokenAmount',
            dataIndex: 'tokenAmount',
            key: 'tokenAmount',
            responsive: ["sm"],
            ...getColumnSearchProps('tokenAmount'),
        },
        {
            title: 'EndTime',
            dataIndex: 'endTime',
            key: 'endTime',
            responsive: ["sm"],
            ...getColumnSearchProps('endTime'),
        },
        // {
        //     title: 'Status',
        //     dataIndex: 'stakedStatus',
        //     key: 'stakedStatus',
        //     responsive: ["sm"],
        //     ...getColumnSearchProps('stakedStatus'),
        // },
        {
            title: 'StakedAt',
            dataIndex: 'stakedAt',
            key: 'stakedAt',
            responsive: ["sm"],
            ...getColumnSearchProps('stakedAt'),
        },
    ];

    const initialize = async (isInit: any) => {
        try {
            if (isInit)
                setLoading(true)

            const stakingOnBehalfHistory = (await axiosInstance.get("/staking_on_behalf/get_all_actions")).data.data || [];

            const tableData = await Promise.all(
                stakingOnBehalfHistory.map(async (item: any, index: any) => {
                    let tableRowData = {
                        key: `${index}`,
                        userId: item?.user?.user_id,
                        userPubkey: item?.user?.wallet_pubkey,
                        tokenAmount: item?.amount,
                        endTime: `${new Date(item.end_time * 1000).toLocaleString()}`,
                        stakedStatus: StakingOnBehalfStatusEnum[item.staked_status],
                        stakedAt: `${new Date(item.created_at).toLocaleString()}`,
                    };

                    return tableRowData;
                })
            )

            setTableData(tableData)

            setLoading(false)
        } catch (err) {
            console.log("err", err)
            setLoading(false)
        }
    }

    useEffect(() => {
        (async () => {
            await initialize(true)
        })()
    }, [mainPoolInfo])

    return (
        <Skeleton loading={isLoading} active>
            <Table
                columns={columns as any}
                dataSource={tableData}
                scroll={{ x: 1000 }}
                loading={isProcessing}
            />
        </Skeleton>
    );
}

export default StakingOnBehalfTable;