import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { SketchOutlined, CreditCardOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { Skeleton, Button, Form, Input, Spin } from 'antd';
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

import StakingOnBehalfTable from "./StakingOnBehalfTable";
import fetchClient from "../../../../utils/fetchClient";
import MainPool from "../../../../interfaces/MainPool";

import "./index.scss";

interface IProps {
    tokenBalance: number,
    contractOwner: string,
    mainPoolAccount: any,
    mainPoolInfo: MainPool,
    onCompleteStakeOnBehalf: any
}

const StakingOnBehalfCard = (props: IProps) => {
    const dispatch = useDispatch();
    const [form1] = Form.useForm();
    const [form2] = Form.useForm();

    const [lockingTimeOfStakingOnBehalf, setLockingTimeOfStakingOnBehalf] = useState<number>();
    const [isLoading, setLoading] = useState(false)
    const [isProcessing, setIsProcessing] = useState(false);
    const [, forceUpdate] = useState({});

    const {
        tokenBalance,
        contractOwner,
        mainPoolAccount,
        mainPoolInfo,
        onCompleteStakeOnBehalf
    } = props;

    const initialize = async () => {
        try {
            setLoading(true)
            setLockingTimeOfStakingOnBehalf(Number(mainPoolInfo?.lockingPeriodOfBehalfStaking) / 30 / 86400)
            setLoading(false)
        } catch (_) {
            setLoading(false)
        }
    }

    const onFinish = async (params: any) => {
        await handleStakeOnBehalf(params)
    };

    const onFinish2 = async (params: any) => {
        await handleSetLockingTimeOfStakingOnBehalf(params.lockingPeriod)
    };

    const handleStakeOnBehalf = async (params: any) => {
        if (params.amount <= 0) {
            toast.warn("Amount can't be less than zero");
            return;
        }

        try {
            setIsProcessing(true);
            await fetchClient.post(
                '/staking_on_behalf/stake',
                {
                    amount: Number(params?.amount),
                    user_id: params?.userId,
                    is_checking_fee: false
                }
            );

            dispatch({ type: "UPDATE_USER_BALANCE", payload: (Number(tokenBalance) - Number(params.amount)) });
            dispatch({ type: "UPDATE_STAKING_VAULT_BALANCE", payload: (Number(mainPoolInfo.stakingVaultBalance) + Number(params.amount)) });
            await onCompleteStakeOnBehalf(Number(params.amount))

            toast.success("Successed to stake on behalf in the main pool");
            setIsProcessing(false)
        } catch (e: any) {
            console.log("stake on behalf err", e);
            if (e?.response?.data && e?.response?.data?.message) {
                toast.error(e?.response?.data?.message);
            } else if (e?.response?.data && e?.response?.data?.detail) {
                toast.error(e?.response?.data?.detail);
            } else {
                toast.error("Failed to stake on behalf in the main pool");
            }
            setIsProcessing(false);
        }
    }

    const handleSetLockingTimeOfStakingOnBehalf = async (lockingPeriod: number) => {
        if (Number(lockingPeriod) <= 0) {
            toast.warn("LockingPeriod can't be less than zero");
            return;
        }

        try {
            setIsProcessing(true)
            await fetchClient.post(
                '/staking_on_behalf/set_locking_time',
                { locking_period: Number(lockingPeriod) * 30 * 86400 }
            );
            toast.success("Successed to set locking period of staking on behalf");
            setLockingTimeOfStakingOnBehalf(lockingPeriod)
            setIsProcessing(false)
        } catch (e: any) {
            console.log("set locking period err", e);
            if (e?.response?.data && e?.response?.data?.message) {
                toast.error(e?.response?.data?.message);
            } else if (e?.response?.data && e?.response?.data?.detail) {
                toast.error(e?.response?.data?.detail);
            } else {
                toast.error("Failed to set locking period of staking on behalf");
            }
            setIsProcessing(false);
        }
    }

    useEffect(() => {
        (async () => {
            forceUpdate({});
            await initialize();
        })()
    }, [contractOwner, mainPoolAccount, mainPoolInfo]);

    return (

        <Skeleton loading={isLoading} active>
            <Spin spinning={isProcessing}>
                <div className="staking-on-behalf-card w-100">
                    <div className="title">Staking on Behalf</div>
                    <Row>
                        <Col md={6} className="staking-on-behalf-form-group mb-2">
                            <div>Set Locking Period(Current Locking Period: {lockingTimeOfStakingOnBehalf} months)</div>
                            <Form
                                form={form1}
                                name="horizontal_login"
                                layout="inline"
                                className="justify-content-between"
                                onFinish={onFinish2}
                            >
                                <Form.Item
                                    className="mb-2"
                                    style={{ width: '75%' }}
                                    name="lockingPeriod"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input locking period!',
                                        },
                                    ]}
                                >
                                    <Input
                                        type={'number'}
                                        min={0}
                                        prefix={<ClockCircleOutlined className="site-form-item-icon" />}
                                        placeholder="Locking Period(month)"
                                    />
                                </Form.Item>
                                <Form.Item shouldUpdate>
                                    {() => (
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            disabled={
                                                !form1.isFieldsTouched(true) ||
                                                !!form1.getFieldsError().filter(({ errors }) => errors.length).length
                                            }
                                        >
                                            Submit
                                        </Button>
                                    )}
                                </Form.Item>
                            </Form>
                        </Col>
                        <Col md={6} className="staking-on-behalf-form-group mb-2">
                            <div>Stake</div>
                            <Form
                                form={form2}
                                name="horizontal_login"
                                layout="inline"
                                className="justify-content-between"
                                onFinish={onFinish}
                            >
                                <Form.Item
                                    className="mb-2"
                                    style={{ width: '50%' }}
                                    name="userId"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input user uuid!',
                                        },
                                    ]}
                                >
                                    <Input
                                        prefix={<CreditCardOutlined className="site-form-item-icon" />}
                                        placeholder="User uuid"
                                    />
                                </Form.Item>
                                <Form.Item
                                    className="mb-2"
                                    style={{ width: '25%' }}
                                    name="amount"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input amount!',
                                        },
                                    ]}
                                >
                                    <Input
                                        prefix={<SketchOutlined className="site-form-item-icon" />}
                                        type="number"
                                        min={0}
                                        placeholder="Amount"
                                    />
                                </Form.Item>
                                <Form.Item shouldUpdate>
                                    {() => (
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            disabled={
                                                !form2.isFieldsTouched(true) ||
                                                !!form2.getFieldsError().filter(({ errors }) => errors.length).length
                                            }
                                        >
                                            Submit
                                        </Button>
                                    )}
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>

                    <StakingOnBehalfTable
                        mainPoolInfo={mainPoolInfo}
                    />
                </div>
            </Spin>
        </Skeleton>
    );
};

export default StakingOnBehalfCard;